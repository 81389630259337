import { Card, NumberFormatArrow } from 'components';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImageEmptyState } from 'screens/layout/components';
import { ProductPriceVariationAndStatusTag } from 'screens/products/components';

export default function ProductCard({ product }) {
  const nameCharsLimit = 65;
  const productName =
    product.name.length >= nameCharsLimit
      ? product.name.substring(0, nameCharsLimit) + '...'
      : product.name;

  return (
    <Link className="keep-color" to={`${product.id}`}>
      <Card style={{ cursor: 'pointer' }}>
        <div className="pb-2">
          <div
            className="mt-4 mb-12"
            style={{
              aspectRatio: 4 / 3,
              backgroundImage: `url(${product.picture})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            {!product.picture && <ImageEmptyState />}
          </div>
          <span
            className="d-block pb-2 fs-15"
            style={{ minHeight: '51px' }}
            title={product.name}
          >
            {productName}
          </span>
          <span className="d-block text fs-13 fw-700 pb-6">
            <b>{product.site_name}</b>
          </span>
          <span className="d-block fs-22 text fw-800 mb-6">
            <NumberFormatArrow
              fixedDecimalScale
              decimalScale={2}
              decimalSeparator=","
              displayType="text"
              format={'0.00'} // TODO: Translate
              prefix={`${product.currency} `}
              thousandSeparator="."
              value={product.last_price}
            />
          </span>
          <span className="d-block fw-600 fs-12 text">
            <ProductPriceVariationAndStatusTag
              status={product.status}
              variation={product.variation}
            />
          </span>
        </div>
      </Card>
    </Link>
  );
}
