import {
  useReportAverageProductPriceQuery,
  useReportClusterQuery,
  useReportTotalProductsQuery,
} from 'api/mercadoradar/MercadoRadarAPIClientV2';
import { NumberFormatArrow, Sidebar } from 'components';
import CardTable from 'components/cards/CardTable';
import { paths } from 'paths';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReportDatePickerRange } from 'screens/components';
import {
  ReportsAveragePriceTable,
  ReportsClustersTable,
  ReportsTotalProductsTable,
} from 'screens/reports/components';
import {
  pushHeaderSubTitle,
  setHeaderLoading,
  setHeaderSubTitle,
  setHeaderTitle,
} from 'store/header';

export default function ReportEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setHeaderLoading(false));

  const attributes = useSelector((state) => state.filters.attributes);
  const categories = useSelector((state) => state.filters.categories);
  const sellers = useSelector((state) => state.filters.sellers);
  const sites = useSelector((state) => state.filters.sites);
  const groupBy = useSelector((state) => state.reports.groupBy);
  const dateGte = useSelector((state) => state.reports.dateGte);
  const dateLte = useSelector((state) => state.reports.dateLte);
  const comparisonDateGte = useSelector(
    (state) => state.reports.comparisonDateGte,
  );
  const comparisonDateLte = useSelector(
    (state) => state.reports.comparisonDateLte,
  );

  const setTitle = () => {
    const title = t('components.sideNav.report');
    const groupByTitle = groupBy.map((item) => {
      return t(`attributeTypes.${item}`);
    });
    dispatch(setHeaderTitle(`${title} - ${groupByTitle.join(', ')}`));

    const subTitle = `${title} - ${groupByTitle.join(', ')}`;
    dispatch(setHeaderSubTitle([{ text: title, path: location.pathname }]));
    dispatch(pushHeaderSubTitle({ text: subTitle, path: location.pathname }));
  };

  const apply = () => {
    if (!hasGroupBy) {
      navigate(paths.product.path);
    }

    setQueryParams({
      attributes,
      categories,
      sellers,
      sites,
      groupBy,
      dateGte,
      dateLte,
      comparisonDateGte,
      comparisonDateLte,
    });
  };

  useEffect(() => {
    apply();
    setTitle();
  }, []);

  useEffect(() => {
    apply();
  }, [dateGte, dateLte]);

  const [queryParams, setQueryParams] = useState({});
  const hasGroupBy = groupBy.length > 0;
  const hasQueryParams = Object.keys(queryParams).length > 0;
  const skip = !hasGroupBy || !hasQueryParams;

  const { data: totalProducts, isFetching: totalProductsIsFetching } =
    useReportTotalProductsQuery(queryParams, { skip });
  const { data: averagePrice, isFetching: averagePriceIsFetching } =
    useReportAverageProductPriceQuery(queryParams, { skip });
  const { data: cluster, isFetching: clusterIsFetching } =
    useReportClusterQuery(queryParams, { skip });

  const isFetching =
    totalProductsIsFetching || averagePriceIsFetching || clusterIsFetching;
  const hasTotalProducts = totalProducts?.results.length > 0;
  const hasAveragePrice = averagePrice?.results.length > 0;
  const hasCluster = !!cluster;

  const isExporting = false;

  // #region functions
  const handleOnClickExport = () => {
    window.alert();
  };

  const handleOnApply = (e) => {
    setQueryParams(e);
    window.scrollTo(0, 0);
    setTitle();
  };
  // #endregion functions

  // #region elements
  const countProductsFormatted = (
    <NumberFormatArrow
      fixedDecimalScale
      decimalScale={0}
      decimalSeparator=","
      displayType="text"
      format={'0'}
      thousandSeparator="."
      value={totalProducts?.count_products}
    />
  );

  const averagePriceFormatted = (
    <NumberFormatArrow
      fixedDecimalScale
      decimalScale={2}
      decimalSeparator=","
      displayType="text"
      format={'0.00'}
      prefix={`${averagePrice?.currency} `}
      thousandSeparator="."
      value={averagePrice?.average_price}
    />
  );

  const totalProductsReportRows = totalProducts?.results.map((group, index) => {
    return (
      <Row key={index} className="mb-20">
        <Col>
          <Row>
            <Col>
              <span>
                {t('screens.reports.groupBy')}:&nbsp;
                <b className="text-primary">
                  {t(`attributeTypes.${group?.name}`)}
                </b>
              </span>
            </Col>
          </Row>
          <Row className="mt-8">
            <Col>
              <ReportsTotalProductsTable
                data={group?.groups}
                groupName={group?.name}
                loading={totalProductsIsFetching}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  });

  const averagePriceReportRows = averagePrice?.results.map(
    (group, averagePriceReportIndex) => {
      return (
        <Row key={averagePriceReportIndex} className="mb-20">
          <Col>
            <Row>
              <Col>
                <span>
                  {t('screens.reports.groupBy')}:&nbsp;
                  <b className="text-primary">{group.name__display}</b>
                </span>
              </Col>
            </Row>
            <Row className="mt-8">
              <Col>
                <ReportsAveragePriceTable
                  currency={averagePrice?.currency}
                  data={group.groups}
                  groupName={group.name__display}
                  loading={averagePriceIsFetching}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    },
  );

  const saveReportButton = (
    <Button
      disabled={!hasTotalProducts && !hasAveragePrice && !hasCluster}
      variant="outline-primary"
      onClick={() => console.log('Error saving report')}
    >
      {t('components.sideBar.buttons.saveReport')}
    </Button>
  );
  // #endregion elements

  return (
    <>
      <Row className="mb-12">
        <Col md={12}>
          <ReportDatePickerRange
            loading={
              totalProductsIsFetching ||
              averagePriceIsFetching ||
              clusterIsFetching
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={4} xxl={3}>
          <Sidebar isFetching={isFetching} onApply={handleOnApply} />
        </Col>

        <Col md={9} xxl={9}>
          <Row className="mb-8">
            <Col md={12}>
              <CardTable
                header={t('screens.reports.totalProducts')}
                headerRight={countProductsFormatted}
                loading={totalProductsIsFetching}
              >
                {totalProductsReportRows}
              </CardTable>
            </Col>
          </Row>

          <Row className="mb-8">
            <Col md={12}>
              <CardTable
                header={t('screens.reports.averagePrice')}
                headerRight={averagePriceFormatted}
                loading={averagePriceIsFetching}
              >
                {averagePriceReportRows}
              </CardTable>
            </Col>
          </Row>

          <Row className="mb-8">
            <Col md={12}>
              <CardTable header="Grupos" loading={clusterIsFetching}>
                <ReportsClustersTable data={cluster} />
              </CardTable>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
