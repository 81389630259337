import AuthAPI, { COOKIE_TOKEN } from 'api/mercadoradar/Auth';
import Axios, { AxiosInstance } from 'axios';
import { I18N_COOKIE, I18N_LOCAL_STORAGE } from 'i18n';
import { DateTime } from 'luxon';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export declare interface IListAPI {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: Array<unknown>;
}

export const listEmptyResponse: IListAPI = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

export default class MercadoRadarAPIClient {
  resource: string;
  retry: number;
  baseUrl: string | undefined;
  client: AxiosInstance;

  constructor(resource: string) {
    this.resource = resource;
    this.retry = 0;
    this.baseUrl = process.env.REACT_APP_MERCADORADAR_API_ENDPOINT;

    this.client = Axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setRequestInterceptors();
    this.setResponseInterceptors();
  }

  setRequestInterceptors() {
    this.client.interceptors.request.use(async (config) => {
      const token = cookies.get(COOKIE_TOKEN);

      const access = token?.access;

      const i18nCookie = cookies.get(I18N_COOKIE);
      const i18nLocalStorage = localStorage.getItem(I18N_LOCAL_STORAGE);

      if (access) {
        config.headers['Authorization'] = `Bearer ${access}`;
      }

      if (i18nCookie) {
        config.headers['Accept-Language'] = i18nCookie;
      }

      if (i18nLocalStorage) {
        config.headers['Accept-Language'] = i18nLocalStorage;
      }

      return config;
    });
  }

  setResponseInterceptors() {
    const current_pathname = window.location.pathname;

    this.client.interceptors.response.use(
      (response) => {
        const contentType = response.headers['content-type'];
        const downloadContentType = ['application/xlsx', 'text/csv'];
        let isDownloadContentType;

        if (contentType) {
          isDownloadContentType = downloadContentType.find((element) => {
            if (contentType.includes(element)) {
              return true;
            }
          });

          if (isDownloadContentType) {
            window.location.href = response.request.responseURL;
          }
        }

        if (process.env.NODE_ENV === 'development' && !isDownloadContentType) {
          console.log(
            'REQUEST: ',
            response.config.url,
            response.config.params,
            response.data,
          );
        }

        return response;
      },
      async (error) => {
        if ([401, 403].includes(error?.response?.status)) {
          if (process.env.ENV !== 'production')
            console.log(error?.response?.status);

          const authApi = new AuthAPI();
          return authApi.logOut(current_pathname);
        }

        return Promise.reject(error.response);
      },
    );
  }

  async create(data) {
    try {
      return this.client.post(this.resource, data)?.data?.results;
    } catch {
      return {};
    }
  }

  async list(params = {}) {
    if (!Object.hasOwnProperty.call(params, 'limit')) {
      params.limit = 50;
    }
    params = this.formatParams(params);

    try {
      const response = await this.client.get(this.resource, { params });
      return await response.data;
    } catch {
      return listEmptyResponse;
    }
  }

  async retrieve(id, params = null) {
    if (params) {
      if (!Object.hasOwnProperty.call(params, 'limit')) {
        params.limit = 50;
      }
    }
    params = this.formatParams(params);
    try {
      const response = await this.client.get(`${this.resource}${id}/`, {
        params,
      });
      return await response?.data;
    } catch {
      return {};
    }
  }

  async delete(id) {
    try {
      return this.client.delete(`${this.resource}${id}/`)?.data?.results;
    } catch {
      return {};
    }
  }

  getExportConfig(format) {
    let accept;

    if (format == 'xlsx') {
      accept = 'application/xlsx';
    } else if (format == 'csv') {
      accept = 'text/csv';
    } else {
      throw 'Format not allowed';
    }

    return { responseType: 'blob', headers: { accept: accept } };
  }

  formatParams(params) {
    if (!params || !Object.keys(params).length) return params;

    for (const key in params) {
      const param = params[key];

      if (param == '' || param == null || param == undefined)
        delete params[key];

      if (param instanceof Date) params[key] = this.formatDate(param);

      if (param instanceof Array) {
        if (param.length > 0) {
          params[key] = params[key].join(',');
        }
      }
    }

    return params;
  }

  formatDate(date) {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
  }
}
