import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ImageEmptyState({
  aspectRatio = 1.33,
  iconSize = 40,
  style = {},
}) {
  return (
    <div
      className="d-flex flex-column align-center justify-content-center"
      style={{
        ...style,
        width: "100%",
        aspectRatio,
        backgroundColor: "var(--bs-gray-100)",
      }}
    >
      <FontAwesomeIcon
        style={{ fontSize: iconSize }}
        className="text-gray-700"
        icon={faImage}
      />
    </div>
  );
}
