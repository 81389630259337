import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setHeaderSubTitle, setHeaderTitle } from 'store/header';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const title = useSelector((state) => state.header.title);
  const subTitle = useSelector((state) => state.header.subTitle);
  const loading = useSelector((state) => state.header.loading);

  const path = location.pathname.split('/');

  const [titleState, setTitleState] = useState('');
  const [subTitleState, setSubTitleState] = useState([]);

  const setTitles = () => {
    const key = path[1];
    const newTitle = t(`components.sideNav.${key}`);
    const newSubTitle = [{ text: newTitle, path: location.pathname }];

    if (path.length == 2) {
      setTitleState(newTitle);
      dispatch(setHeaderTitle(newTitle));

      setSubTitleState(newSubTitle);
      dispatch(setHeaderSubTitle(newSubTitle));
    }
  };

  useEffect(() => {
    setTitles();
  }, [location]);

  useEffect(() => {
    setTitleState(title);
    setSubTitleState(subTitle);
  }, [title, subTitle]);

  const skeletonTitle = <Skeleton height="33px" width="33%" />;
  const skeletonSubTitle = <Skeleton height="17px" width="20%" />;
  const skeleton = (
    <div>
      {skeletonTitle}
      {skeletonSubTitle}
    </div>
  );

  const headerContent = (
    <>
      <h1 className="d-block fs-22 fw-800">{titleState}</h1>
      <div className="d-block">
        {subTitleState.map((item, index) => (
          <div key={index} className="d-inline">
            {index < subTitleState.length - 1 ? (
              <Link className="fs-12" to={item.path}>
                {item.text}
              </Link>
            ) : (
              <span className="fs-12 text-gray-800">{item.text}</span>
            )}
            {index < subTitleState.length - 1 && (
              <span className="fs-12 text-gray-800 mx-2">/</span>
            )}
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div
      className="mt-8 mb-12 pb-8"
      style={{
        borderBottom: 'solid 1px var(--bs-gray-200)',
      }}
    >
      {loading ? skeleton : headerContent}
    </div>
  );
};

export default Header;
