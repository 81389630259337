import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse as AntdCollapse, Tag } from 'antd';
import { useAttributeTypeListQuery } from 'api/mercadoradar/MercadoRadarAPIClientV2';
import CollapseList from 'components/ReportsFilters/CollapseList';
import CollapseTree from 'components/ReportsFilters/CollapseTree';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { setCount, setInitialCount } from 'store/filtersSlice';
import { CATEGORY } from 'types/AttributeType';

export default function Collapse() {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState([]);

  const filtersCount = useSelector((state) => state.filters.count);
  const { data: filters, isFetching: filtersIsFetching } =
    useAttributeTypeListQuery();

  useEffect(() => {
    const initialFiltersCount = Array.from({ length: filters?.length }).map(
      () => 0,
    );
    dispatch(setInitialCount(initialFiltersCount));
    dispatch(setCount(initialFiltersCount));
  }, [filters, dispatch]);

  const expandIcon = ({ isActive }) =>
    !filtersIsFetching ? (
      isActive ? (
        <MinusOutlined />
      ) : (
        <PlusOutlined />
      )
    ) : (
      <></>
    );

  const handleChange = (keys) => {
    keys.forEach((key) => {
      if (!activeKey.includes(key)) {
        const filter = filters[key];
      }
    });
    setActiveKey(keys);
  };

  const handleCheckboxChange = (e, index) => {
    const newFiltersCount = [...filtersCount];
    newFiltersCount[index] = e;
    dispatch(setCount(newFiltersCount));
  };

  const skeleton = Array.from({ length: 6 }).map((_, index) => (
    <AntdCollapse.Panel
      key={`skeleton-panel-${index}`}
      collapsible={filtersIsFetching ? 'disabled' : null}
      header={<Skeleton height="22" width="100%" />}
      style={{ width: '100%' }}
    ></AntdCollapse.Panel>
  ));

  const header = (text, index) => {
    const tag = (
      <Tag bordered={false} className="ms-4" color="blue">
        {filtersCount[index]}
      </Tag>
    );
    return (
      <>
        {text}
        {filtersCount[index] > 0 && tag}
      </>
    );
  };

  const collapseChildren = (name, index) => {
    const tree = (
      <CollapseTree onChange={(e) => handleCheckboxChange(e, index)} />
    );
    const list = (
      <CollapseList
        index={index}
        name={name}
        onChange={(e) => handleCheckboxChange(e, index)}
      />
    );

    return name === CATEGORY ? tree : list;
  };

  const collapseFilters = filters?.map((filter, index) => (
    <AntdCollapse.Panel key={index} header={header(filter?.text, index)}>
      <div className="mb-6">{collapseChildren(filter?.name, index)}</div>
    </AntdCollapse.Panel>
  ));

  return (
    <AntdCollapse
      accordion={true}
      activeKey={activeKey}
      bordered={false}
      expandIcon={expandIcon}
      expandIconPosition="end"
      onChange={handleChange}
    >
      {filtersIsFetching ? skeleton : collapseFilters}
    </AntdCollapse>
  );
}
