import Card from "components/cards/Card";
import Collapse from "components/ReportsFilters/Collapse";
import { useTranslation } from "react-i18next";

export default function ReportsFilters() {
  const { t } = useTranslation();

  return (
    <Card
      header={t("components.sideBar.filters")}
      className="d-flex flex-column justify-content-between"
      style={{ minHeight: "158px" }}
    >
      <Collapse />
    </Card>
  );
}