import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

const yesterday = DateTime.now().minus({ days: 1 });
const defaultDateGte = yesterday.minus({ days: 6 });
const defaultDateLte = yesterday;
const defaultComparisonDateLte = defaultDateGte.minus({ days: 1 });
const defaultComparisonDateGte = defaultComparisonDateLte.minus({
  days: defaultDateLte.diff(defaultDateGte, 'days').days,
});

const initialState = {
  groupBy: [],
  dateGte: defaultDateGte.toISODate(),
  dateLte: defaultDateLte.toISODate(),
  comparisonDateGte: defaultComparisonDateGte.toISODate(),
  comparisonDateLte: defaultComparisonDateLte.toISODate(),
  detail: { groupBy: '', params: {} },
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setGroupBy(state, action) {
      state.groupBy = action.payload;
    },
    addGroupBy(state, action) {
      state.groupBy = [...state.groupBy, action.payload];
    },
    removeGroupBy(state, action) {
      state.groupBy = state.groupBy.filter((item) => item !== action.payload);
    },
    resetGroupBy(state) {
      state.groupBy = [];
    },
    setDateGte(state, action) {
      state.dateGte = DateTime.fromJSDate(action.payload).toISODate();
      const newDateGte = DateTime.fromJSDate(action.payload);
      const dateLte = DateTime.fromISO(state.dateLte);
      const duration = dateLte.diff(newDateGte, 'days').days;
      const newComparisonDateLte = newDateGte.minus({ days: 1 });

      if (duration > 0) {
        state.comparisonDateGte = newComparisonDateLte
          .minus({ days: duration })
          .toISODate();
      } else {
        state.comparisonDateGte = newComparisonDateLte.toISODate();
      }

      state.comparisonDateLte = newComparisonDateLte.toISODate();
    },
    setDateLte(state, action) {
      state.dateLte = DateTime.fromJSDate(action.payload).toISODate();
      const newDateLte = DateTime.fromJSDate(action.payload);
      const dateGte = DateTime.fromISO(state.dateGte);
      const duration = newDateLte.diff(dateGte, 'days').days;
      const newComparisonDateLte = dateGte.minus({ days: 1 });

      if (duration > 0) {
        state.comparisonDateGte = newComparisonDateLte
          .minus({ days: duration })
          .toISODate();
      } else {
        state.comparisonDateGte = newComparisonDateLte.toISODate();
      }

      state.comparisonDateLte = newComparisonDateLte.toISODate();
    },
    setDetail(state, action) {
      state.detail.groupBy = action.payload.groupBy;
      state.detail.params = action.payload.params;
    },
  },
});

export const {
  setGroupBy,
  addGroupBy,
  removeGroupBy,
  resetGroupBy,
  setDateGte,
  setDateLte,
  setDetail,
} = reportsSlice.actions;
export default reportsSlice.reducer;
