import PropTypes from 'prop-types';
import { Card as BootstrapCard, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

Card.propTypes = {
  header: PropTypes.string,
  headerRight: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  props: PropTypes.object,
};

export default function Card({
  header = null,
  headerRight = null,
  loading = false,
  className = '',
  style = {},
  children,
  ...props
}) {
  return (
    <BootstrapCard {...props} className="px-12 pt-2 pb-10">
      {header && (
        <Row>
          <Col className="card-header mt-9 mb-6 ps-4">
            {loading ? (
              <Skeleton height={26} width={'25%'} />
            ) : (
              <h3 className="fw-700 text fs-17">{header}</h3>
            )}
          </Col>
          <Col className="card-header text-end mt-9 mb-6 pe-4">
            {headerRight &&
              (loading ? (
                <Skeleton height={26} width={'50%'} />
              ) : (
                <h3 className="fw-700 text fs-17">{headerRight}</h3>
              ))}
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <div className={`${className ? className : ''}`} style={style}>
            {children}
          </div>
        </Col>
      </Row>
    </BootstrapCard>
  );
}
