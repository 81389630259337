import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { DateTime } from "luxon";
import { Col, Row, Spinner } from "react-bootstrap";
import { EmptyState } from "screens/layout/components";

export const chartColors = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];

declare interface IChartPoint {
  x: string | number | Date;
  y: number;
}

export declare interface IChartSeries {
  name: string;
  data: IChartPoint[];
}

export default function Chart({
  type = "line",
  title = undefined,
  yAxisTitle = undefined,
  timeseries = false,
  height = undefined,
  showLegend = true,
  customOptions = {},
  loading = false,
  series,
  children,
  callback,
}) {
  const hasSeries = series.length > 0; 
  let hasData = false;

  for (const index in series) {
    const serie = series[index];
    if (serie.data.length > 0) {
      hasData = true;
      break;
    }
  }

  const isEmpty = !hasSeries || !hasData;

  function getHeight() {
    const windowHeight =
      typeof window != "undefined" ? window.innerHeight / 2 : "100%";
    return height ? height : windowHeight;
  }

  function getOptions() {
    return {
      chart: {
        type,
        height: getHeight(),
        marginRight: 90,
        animation: false,
      },
      plotOptions: {
        series: {
          animation: false,
          marker: { symbol: "circle" },
        },
      },
      title: {
        text: title ? title : null,
      },
      legend: {
        enabled: showLegend,
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisTitle ? yAxisTitle : null,
        },
      },
      xAxis: {
        type: timeseries ? "datetime" : "linear",
        labels: {
          format: "{value:%d/%m/%y}",
        },
      },
      series: [...getSeries()],
      colors: [...chartColors],
      tooltip: {
        backgroundColor: "transparent",
        useHTML: true,
        borderWidth: 0,
        shadow: false,
        style: {
          pointerEvents: "auto",
        },
      },
      ...customOptions,
    };
  }

  function getSeries(): IChartSeries {
    if (series == undefined) {
      throw "No series defined";
    }

    if (timeseries) {
      const newSeries = series.map((serie) => {
        serie.data = serie.data.map((point) => {
          if (typeof point.x == "string")
            point.x = DateTime.fromISO(point.x).toJSDate();

          return point;
        });

        return serie;
      });

      return newSeries;
    }
  }

  const options = getOptions();

  const spinnerElement = (
    <Row>
      <Col
        style={{ minHeight: "50vh" }}
        className="d-flex justify-content-center"
      >
        <Spinner
          className="align-self-center"
          animation="border"
          variant="primary"
        />
      </Col>
    </Row>
  );

  const chartElement = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={callback}
      />
      {children}
    </>
  );

  const emptyState = <Row>
    <Col
      style={{ minHeight: "50vh" }}
      className="d-flex justify-content-center"
    >
      <div className="align-self-center">
        <EmptyState  />
      </div>
    </Col>
  </Row>;

  return (
    <>
      {loading && spinnerElement}
      {!loading && (!isEmpty ? chartElement : emptyState)}
    </>
  );
}
