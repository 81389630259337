import auth from 'paths/auth';

const paths = {
  ...auth,
  base: {
    children: ['product', 'report', 'settings'],
  },
  product: {
    id: 'product',
    title: 'Produtos',
    path: '/product',
    icon: 'box',
  },
  report: {
    id: 'report',
    title: 'Relatórios',
    path: '/report',
    icon: 'bar-chart',
  },
  settings: {
    id: 'settings',
    title: 'Configurações',
    path: '/settings',
    icon: 'settings',
  },
  reportEdit: {
    title: 'Relatórios',
    path: '/report/edit',
    sideNavVisible: false,
  },
  reportDetail: {
    title: 'Relatórios',
    path: '/report/edit/:report',
    sideNavVisible: false,
  },
};

export { paths };
