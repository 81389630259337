import { TokenType } from "api/mercadoradar/Auth";
import MercadoRadarAPIClient from "api/mercadoradar/MercadoRadarAPIClient";

const resource = "user/";

declare interface IAccount {
  id: number;
  name: string;
}

export declare interface IUser {
  id: number | null;
  name: string | null;
  email: string | null;
  account: IAccount | null;
  token: TokenType;
}

export default class UserAPI extends MercadoRadarAPIClient {
  constructor() {
    super(resource);
  }

  me() {
    const get = async () => {
      const response = await this.client.get(`${this.resource}me/`);
      const data = await response.data;
      return data;
    };

    const update = async (data) => {
      const response = await this.client.put(`${this.resource}me/`, {...data});
      return response?.data;
    };

    return {
      get,
      update
    };
  }
}
