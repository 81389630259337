import { Pagination as BootstrapPagination } from 'react-bootstrap';

export default function Pagination({
  totalItems,
  itemsPerPage,
  activePage,
  onItemClick,
  ...props
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPagesToShow = 10;
  let startPage, endPage;

  if (totalPages <= maxPagesToShow) {
    startPage = 1;
    endPage = totalPages;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
    if (activePage <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (activePage + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    } else {
      startPage = activePage - maxPagesBeforeCurrentPage;
      endPage = activePage + maxPagesAfterCurrentPage + 1;
    }
  }

  const items = [];
  for (let index = startPage; index <= endPage; index++) {
    items.push(
      <BootstrapPagination.Item
        key={index}
        active={index === activePage}
        onClick={() => onItemClick(index)}
        style={{ width: 52, textAlign: 'center' }}
      >
        {index}
      </BootstrapPagination.Item>,
    );
  }

  const pagination = (
    <BootstrapPagination {...props}>
      <BootstrapPagination.First
        onClick={() => onItemClick(1)}
        style={{ width: 52, textAlign: 'center' }}
      />
      <BootstrapPagination.Prev
        onClick={() => onItemClick(activePage - 1)}
        style={{ width: 52, textAlign: 'center' }}
      />
      {items}
      <BootstrapPagination.Next
        onClick={() => onItemClick(activePage + 1)}
        style={{ width: 52, textAlign: 'center' }}
      />
      <BootstrapPagination.Last
        onClick={() => onItemClick(totalPages)}
        style={{ width: 52, textAlign: 'center' }}
      />
    </BootstrapPagination>
  );

  const renderElement = totalPages > 1 ? pagination : <></>;

  return renderElement;
}
