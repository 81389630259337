import MercadoRadarAPIClient from "api/mercadoradar/MercadoRadarAPIClient";

const resource = "v2/site/";

export default class SiteAPI extends MercadoRadarAPIClient {
  constructor() {
    super(resource);
  }

  async coverage() {
    const response = await this.client.get(`${this.resource}temp_coverage/`);
    const data = await response.data;
    return data;
  }
}
