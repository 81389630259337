import { createColumnHelper } from '@tanstack/react-table';
import { IReportCluster } from 'api/mercadoradar/Report';
import classNames from 'classnames';
import { NumberFormatArrow, Table } from 'components';
import { useSearchParams } from 'hooks/useSearchParams';
import { DateTime } from 'luxon';
import { paths } from 'paths';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ImageEmptyState } from 'screens/layout/components';
import { ProductStatusTag } from 'screens/products/components';
import styles from 'screens/reports/components/reportsClusterTable.module.css';

export default function ReportsClusterProductsTable({ data, loading = false }) {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const dateGte = searchParams?.date__gte;
  const dateLte = searchParams?.date__lte;

  const currency = data[0]?.currency;

  const columnHelper = createColumnHelper<IReportCluster>();
  const enableColumnResizing = true;
  const columns = [
    columnHelper.accessor('name', {
      header: t('components.tables.product'),
      meta: {
        width: 0.3,
        cellClassName: 'd-flex align-items-center',
      },
      cell: (cell) => {
        const productPath = `${paths.product.path}/${cell.row.original.id}?date__gte=${dateGte}&date__lte=${dateLte}`;
        return (
          <div className="d-flex flex-row">
            <div
              className={classNames(
                styles.reportsViewTableThumbnailWrapper,
                'd-flex',
                'flex-column',
                'justify-content-center',
              )}
            >
              {cell.row.original.picture && (
                <Link
                  rel="noreferrer"
                  style={{ display: 'block', width: '100%', height: '100%' }}
                  target="_blank"
                  to={productPath}
                >
                  <div
                    className={classNames(styles.reportsViewTableThumbnail)}
                    style={{
                      backgroundImage: `url(${cell.row.original.picture})`,
                    }}
                  ></div>
                </Link>
              )}
              {!cell.row.original.picture && <ImageEmptyState iconSize={20} />}
            </div>
            <div className="d-flex flex-column justify-content-center ps-6">
              <Link target="_blank" to={productPath}>
                {cell.getValue()}
              </Link>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('site__name', {
      header: t('components.tables.siteSeller'),
      meta: {
        cellClassName: 'd-flex align-items-center',
        width: 0.1,
      },
      sortingFn: (rowA, rowB, columnId) => {
        let a = `${rowA.original.site__name} ${rowA.original.seller?.name}`;
        a = a.replace(/\s/g, '');

        let b = `${rowB.original.site__name} ${rowB.original.seller?.name}`;
        b = b.replace(/\s/g, '');

        return a > b ? 1 : -1;
      },
      cell: (cell) => {
        return (
          <>
            <span className="d-block">{cell.getValue()}</span>
            {cell.row.original.seller?.name &&
              cell.row.original.seller?.url && (
                <a
                  className="d-block"
                  href={cell.row.original.seller?.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span>{cell.row.original.seller?.name}</span>
                </a>
              )}
          </>
        );
      },
    }),
    columnHelper.accessor('status__display', {
      header: t('components.tables.status'),
      meta: {
        cellClassName: 'd-flex align-items-center',
        width: 0.1,
      },
      cell: (cell) => (
        <div style={{ position: 'relative', top: '26%' }}>
          <div className="d-grid">
            <ProductStatusTag status={cell.row.original.status} />
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('history_updated_at', {
      header: t('components.tables.updatedAt'),
      meta: {
        width: 0.1,
        cellClassName: 'd-flex align-items-center',
      },
      cell: (cell) => {
        return DateTime.fromISO(cell.getValue()).toFormat('dd/MM/yy hh:mm'); // TODO: Localte
      },
    }),
    columnHelper.accessor('sales_price', {
      header: t('components.tables.averagePrice'),
      meta: {
        headerClassName: 'justify-content-end',
        width: 0.1,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          ifZero={'-'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('price_variation', {
      header: t('components.tables.averagePriceVariation', { currency }),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'd-flex align-items-center justify-content-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          arrow={true}
          better="positive"
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          ifZero={'-'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('price_variation_percent', {
      header: 'Var. (%)',
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'd-flex align-items-center justify-content-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          arrow={true}
          better="positive"
          decimalScale={1}
          decimalSeparator=","
          displayType="text"
          format={'0.0'}
          ifZero={'-'}
          suffix="%"
          thousandSeparator="."
          value={cell.getValue() * 100}
        />
      ),
    }),
    columnHelper.accessor('installment_price', {
      header: t('components.tables.averageInstallmentPrice'),
      meta: {
        width: 0.1,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          ifZero={'-'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
  ];
  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    loading,
  };

  return <Table {...tableProps} />;
}
