import { Col } from 'react-bootstrap';
import { ProductCard, ProductCardSkeleton } from 'screens/products/components';

export default function ProductsGrid({ products, loading = true }) {
  const productCard = products?.map((product, index) => {
    return (
      <Col key={index} className="mb-8" md={4}>
        <ProductCard product={product} />
      </Col>
    );
  });
  const productCardSkeleton = Array(8)
    .fill(null)
    .map((_, index) => {
      return (
        <Col key={index} className="mb-8" md={4}>
          <ProductCardSkeleton />
        </Col>
      );
    });

  return <>{loading ? productCardSkeleton : productCard}</>;
}
