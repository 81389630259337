import { ISeller } from "api/mercadoradar/Seller";
import { Card, NumberFormatArrow } from "components";
import { DateTime } from "luxon";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { ImageEmptyState } from "screens/layout/components";
import {
  ProductDetailAttributesTable, ProductPriceVariationAndStatusTag
} from "screens/products/components";

// TODO: Create a Interface in APIProduct
declare interface IProductDetailHeader {
  attributes: Array<unknown>;
  loading: boolean;
  path_from_root_category: string;
  picture: string | undefined;
  price_max: number | undefined;
  price_min: number | undefined;
  price_variation_percent: number | undefined;
  price: number;
  seller: ISeller | undefined;
  site_name: string | undefined;
  status: string | undefined;
  title: string;
  total_products: number | undefined;
  url: string | undefined;
  currency: string;
}

export enum sellerCorporateIdentificationType {
  CNPJ = "CNPJ",
}

export default function ProductDetailHeader({
  attributes = [],
  currency,
  loading,
  path_from_root_category = undefined,
  picture = undefined,
  price_max = undefined,
  price_min = undefined,
  price_variation_percent = undefined,
  price,
  seller = undefined,
  site_name = undefined,
  status = undefined,
  title,
  total_products = undefined,
  url = undefined,
  price_updated_at = undefined
}: IProductDetailHeader) {
  const { t } = useTranslation();

  const formattedSellerCorporateIdentificationNumber = () => {
    if (seller?.corporate_identification_type === sellerCorporateIdentificationType.CNPJ) {
      return seller?.corporate_identification_number.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
    }
  };
  
  const thumbnail = 
    (<div
      style={{
        aspectRatio: 4 / 3,
        backgroundImage: `url(${picture})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {loading && <Skeleton width={"100%"} height={"100%"}/>}
      {!loading && !picture && <ImageEmptyState />}
    </div>);

  const detailsTitle = (
    url ? (
      <a
        className="fs-17 fw-700 d-block mb-1"
        href={url}
        rel="noreferrer"
        target="_blank"
      >
        {title}
      </a>)
      : <span className="fs-17 fw-700 d-block mb-1">{loading ? <Skeleton width={"75%"}/> : title}</span>);

  const detailsTotalProducts = (
    total_products && (
      <span className="fs-12 gray-800 d-block mb-1">
        {total_products == 1 ? (
          "1 produto"
        ) : (
          <NumberFormatArrow
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            format={"0"}
            suffix={` ${t("screens.clusters.header.products", {count: total_products})}`}
            decimalScale={0}
            fixedDecimalScale
            value={total_products}
          />
        )}
      </span>));

  const detailsSiteName = (<span className="fs-15 fw-700 d-block">{loading ? <Skeleton width={"25%"} /> : site_name}</span>);

  const detailsSeller = 
    <>
      {seller?.name && 
        <span className="mt-6 text-gray-800 fs-12 d-block">
          {t("screens.products.details.header.soldBy")}:{" "}<a href={seller?.url} target="_blank" rel="noreferrer">{seller?.name}</a>
        </span>}
      {seller?.corporate_name && <span className="mt-1 text-gray-800 fs-12 d-block">{t("screens.products.details.header.corporateName")}:{" "}{seller?.corporate_name}</span>}
      {seller?.corporate_identification_type && seller?.corporate_identification_number && <span className="mt-1 text-gray-800 fs-12 d-block">{seller?.corporate_identification_type}:{" "}{formattedSellerCorporateIdentificationNumber()}</span>}
      {seller?.address && <span className="mt-1 text-gray-800 fs-12 d-block">{t("screens.products.details.header.address")}:{" "}{seller?.address}</span>}
    </>;

  const detailsPrice = (
    <span className="fs-34 d-block mt-6">
      {loading ?
        (<Skeleton width={"50%"}/>)
        : (
          <>
            <span className="fw-800">
              <NumberFormatArrow
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currency} `}
                format={"0.00"} // TODO: Translate
                decimalScale={2}
                fixedDecimalScale
                value={price}
              />
            </span>
            {price_updated_at && (
              <span className="text-gray-800 fs-12 d-block">
                {t("screens.products.details.header.updatedAt")}:{" "}
                {DateTime.fromISO(price_updated_at).toFormat("dd/MM/yy hh:mm")}
              </span>
            )}
          </>
        )}
        
    </span>);

  const detailsPriceRange = (
    price_min && price_max && (
      <span className="fs-12 d-block">
        <NumberFormatArrow
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          format={"0.00"}
          prefix={`${t("screens.clusters.header.from")} ${currency} `}
          decimalScale={2}
          fixedDecimalScale
          value={price_min}
        />
        <NumberFormatArrow
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          format={"0.00"}
          prefix={` ${t("screens.clusters.header.to")} ${currency} `}
          decimalScale={2}
          fixedDecimalScale
          value={price_max}
        />
      </span>));

  const detailsPriceVariation = (
    price_variation_percent != 0 &&
    price_variation_percent != undefined && (
      <span className="fs-12 mt-6">
        <ProductPriceVariationAndStatusTag
          variation={price_variation_percent}
          status={status || "ACTIVE"}
        />
      </span>));

  return (
    <Card>
      <Row className="pt-8">
        <Col md={3} className="pt-8 px-10 d-flex flex-column justify-content-center">
          {thumbnail}
        </Col>
        <Col md={4} className="d-flex flex-column justify-content-center">
          {detailsTitle}
          {detailsTotalProducts}
          {detailsSiteName}
          {detailsSeller}
          {detailsPrice}
          {detailsPriceRange}
          {detailsPriceVariation}
        </Col>
        <Col md={5} className="d-flex flex-column justify-content-center">
          <ProductDetailAttributesTable
            attributes={attributes}
            loading={loading}
            path_from_root_category={path_from_root_category}
            site_name={site_name}
          />
        </Col>
      </Row>
    </Card>
  );
}
