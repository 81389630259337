import { faFaceFrown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function EmptyState({ textKey = "components.emptyState.nothingFound", iconSize = 60, className = "fs-4" }) {
  const { t } = useTranslation();


  return (
    <Row className="d-flex justify-content-center mb-10">
      <Col md="auto" className="text-center">
        <span className="d-block mb-10" style={{ fontSize: iconSize }}>
          <FontAwesomeIcon className="text-gray-700" icon={faFaceFrown} />
        </span>
        <span className={"text text-gray-800" + className}>{t(textKey)}</span>
      </Col>
    </Row>
  );
}
