import { createColumnHelper } from '@tanstack/react-table';
import { Table } from 'components';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

export default function CoverageTable({ data, sorting, loading }) {
  type CoverageList = {
    id: number;
    name: string;
    total_products: number;
    coverage_one_day: number;
    coverage_seven_days: number;
    coverage_thirty_days: number;
  };

  const { t } = useTranslation();

  const columnHelper = createColumnHelper<CoverageList>();
  const enableColumnResizing = true;
  const enableSorting = true;

  const columns = [
    columnHelper.accessor('name', {
      header: t('screens.settings.coverageTable.name'),
      meta: {
        width: 0.2,
        cellClassName: 'd-flex align-items-center',
      },
      cell: (cell) => <>{cell.getValue()}</>,
    }),
    columnHelper.accessor('total_products', {
      header: t('screens.settings.coverageTable.totalProducts'),
      meta: {
        width: 0.2,
        headerClassName: 'd-flex align-items-center justify-content-center',
        cellClassName: 'd-flex align-items-center justify-content-center',
      },
      cell: (cell) => (
        <NumericFormat
          fixedDecimalScale
          decimalScale={0}
          decimalSeparator=","
          displayType="text"
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('coverage_one_day', {
      header: t('screens.settings.coverageTable.lastDay'),
      meta: {
        width: 0.2,
        headerClassName: 'd-flex align-items-center justify-content-center',
        cellClassName: 'd-flex align-items-center justify-content-center',
      },
      cell: (cell) => (
        <span className="text fs-22">
          <Badge
            pill
            bg={
              cell.getValue() >= 0.9
                ? 'success-soft'
                : cell.getValue() >= 0.7
                  ? 'warning-soft'
                  : 'danger-soft'
            }
            className="me-2 mb-2 text-1"
          >
            <strong>
              <NumericFormat
                fixedDecimalScale
                decimalScale={0}
                decimalSeparator=","
                displayType="text"
                suffix="%"
                thousandSeparator="."
                value={100 * cell.getValue()}
              />
            </strong>
          </Badge>
        </span>
      ),
    }),
    columnHelper.accessor('coverage_seven_days', {
      header: t('screens.settings.coverageTable.lastSevenDays'),
      meta: {
        width: 0.2,
        headerClassName: 'd-flex align-items-center justify-content-center',
        cellClassName: 'd-flex align-items-center justify-content-center',
      },
      cell: (cell) => (
        <span className="text fs-22">
          <Badge
            pill
            bg={
              cell.getValue() >= 0.9
                ? 'success-soft'
                : cell.getValue() >= 0.7
                  ? 'warning-soft'
                  : 'danger-soft'
            }
            className="me-2 mb-2 text-1"
          >
            <strong>
              <NumericFormat
                fixedDecimalScale
                decimalScale={0}
                decimalSeparator=","
                displayType="text"
                suffix="%"
                thousandSeparator="."
                value={100 * cell.getValue()}
              />
            </strong>
          </Badge>
        </span>
      ),
    }),
    columnHelper.accessor('coverage_thirty_days', {
      header: t('screens.settings.coverageTable.lastThirtyDays'),
      meta: {
        width: 0.18,
        headerClassName: 'd-flex align-items-center justify-content-center',
        cellClassName: 'd-flex align-items-center justify-content-center',
      },
      cell: (cell) => (
        <span className="text fs-22">
          <Badge
            pill
            bg={
              cell.getValue() >= 0.9
                ? 'success-soft'
                : cell.getValue() >= 0.7
                  ? 'warning-soft'
                  : 'danger-soft'
            }
            className="me-2 mb-2 text-1"
          >
            <strong>
              <NumericFormat
                fixedDecimalScale
                decimalScale={0}
                decimalSeparator=","
                displayType="text"
                suffix="%"
                thousandSeparator="."
                value={100 * cell.getValue()}
              />
            </strong>
          </Badge>
        </span>
      ),
    }),
  ];
  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    enableSorting,
    sorting,
  };

  return <Table {...tableProps} loading={loading} />;
}
