import { ExportAPI } from "api/mercadoradar";
import { useNavigate } from "hooks/useNavigate";
import { paths } from "paths";
import { useEffect } from "react";
import { useParams } from "react-router-dom";


// TODO: Mover para um slicer e reproveitar a lógica nos botões de exportação
// Com isso, podemos evitar o uso de navigate e redirect
export default function ExportHistoryByDatePage() {
  const { date } = useParams();
  const navigate = useNavigate();
  const exportApi = new ExportAPI();

  async function getHistoryByDate(date: string) {
    const data = await exportApi.getHistoryByDate(date);
    
    if (data.redirect) {
      navigate(paths.product.path);
    }
  }

  useEffect(() => {
    getHistoryByDate(date);
  });

  return <></>;
}