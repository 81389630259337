import { ReportsFilters, ReportsGroupBy } from 'components';
import { paths } from 'paths';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initialState, resetFilters } from 'store/filtersSlice';
import { resetGroupBy } from 'store/reportsSlice';

export default function Sidebar({ isFetching, offset = 0, onApply }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const attributes = useSelector((state) => state.filters.attributes);
  const categories = useSelector((state) => state.filters.categories);
  const sites = useSelector((state) => state.filters.sites);
  const sellers = useSelector((state) => state.filters.sellers);
  const groupBy = useSelector((state) => state.reports.groupBy);

  const hasGroupBy = groupBy.length > 0;

  const checkNavigate = () => {
    if (hasGroupBy) {
      navigate(paths.reportEdit.path);
    } else {
      navigate(paths.product.path);
    }
  };

  const handleOnClickApply = () => {
    checkNavigate();

    if (onApply) {
      onApply({
        attributes,
        categories,
        sites,
        sellers,
        groupBy,
        offset,
      });
    }
  };

  const handleOnClickReset = () => {
    dispatch(resetFilters());
    dispatch(resetGroupBy());
    if (onApply) {
      onApply(initialState);
    }

    navigate(paths.product.path);
  };

  return (
    <>
      <Row className="mb-8">
        <Col>
          <ReportsFilters />
        </Col>
      </Row>

      <Row className="mb-8">
        <Col>
          <ReportsGroupBy />
        </Col>
      </Row>

      <Row>
        <Col className="d-grid">
          <div className="d-grid">
            <Button
              disabled={isFetching}
              variant="primary"
              onClick={handleOnClickApply}
            >
              {t('components.sideBar.buttons.apply')}
            </Button>
          </div>
        </Col>
        <Col className="d-grid">
          <Button
            disabled={isFetching}
            variant="outline-primary"
            onClick={handleOnClickReset}
          >
            {t('components.sideBar.buttons.reset')}
          </Button>
        </Col>
      </Row>
    </>
  );
}
