import { useCallback } from 'react';
import { useSearchParams as useSearchParams_ } from 'react-router-dom';

export function useSearchParams(params?) {
  const [searchParams_, setSearchParams_] = useSearchParams_();
  const searchParams = Object.fromEntries([...searchParams_]);

  const setSearchParams = useCallback(
    (params) => {
      setSearchParams_(params);
    },
    [params],
  );

  return [searchParams, setSearchParams] as const;
}
