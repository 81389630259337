import { ConfigProvider } from "antd";
import { history, useNavigate } from "hooks/useNavigate";
import { Provider } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { store } from "store";

export function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  const theme = {
    token: {
      colorPrimary: "#0245f5",
    },
    components: {
      Collapse: {
        contentPadding: 0,
      },
    }
  };

  return (
    <>
      <Provider store={store}>
        <ConfigProvider 
          theme={theme}
        >
          <Outlet />
        </ConfigProvider>
      </Provider>
    </>);
}