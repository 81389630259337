import { lazy } from 'react';

const AuthCreateScreen = lazy(() => import('screens/auth/AuthCreateScreen'));
const AuthForgotPasswordScreen = lazy(
  () => import('screens/auth/AuthForgotPasswordScreen'),
);
const AuthLoginScreen = lazy(() => import('screens/auth/AuthLoginScreen'));
const AuthResetPasswordScreen = lazy(
  () => import('screens/auth/AuthResetPasswordScreen'),
);

export {
  AuthCreateScreen,
  AuthForgotPasswordScreen,
  AuthLoginScreen,
  AuthResetPasswordScreen,
};
