import { Chart } from 'components';
import { IChartSeries } from 'components/charts/Chart';
import { Chart as HighchartsChart } from 'highcharts';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { ReportsClusterChartTooltip } from 'screens/reports/components';

export default function ReportsClusterChart({
  data,
  aggregateBy,
  loading = false,
}) {
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const callback = useCallback((chart: HighchartsChart) => {
    setChart(chart);
  }, []);

  const series: IChartSeries = _.orderBy(data, ['quantity_products'], ['desc']);

  return (
    <Chart
      callback={callback}
      loading={loading}
      series={series}
      showLegend={false}
      timeseries={true}
    >
      <ReportsClusterChartTooltip aggregateBy={aggregateBy} chart={chart} />
    </Chart>
  );
}
