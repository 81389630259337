import ClusterAPI, {
  ICluster,
  IClusterProduct,
} from 'api/mercadoradar/Cluster';
import { IReportClusterDetailSites } from 'api/mercadoradar/Report';
import { Card, Pagination, Select } from 'components';
import { options as aggregateByAvailableOptions } from 'enums/chartAggregateByAvailableOptions';
import { options as groupByAvailableOptions } from 'enums/chartGroupByAvailableOptions';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReportDatePickerRange } from 'screens/components';
import { ProductDetailHeader } from 'screens/products/components';
import {
  ReportsClusterChart,
  ReportsClusterProductsTable,
  ReportsClusterSitesTable,
} from 'screens/reports/components';
import {
  pushHeaderSubTitle,
  setHeaderLoading,
  setHeaderTitle,
} from 'store/header';

export default function ReportDetailPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const groupBy = useSelector((state) => state.reports.detail.groupBy);
  const clusterProductsLimit = 50;

  // #region API
  const clusterApi = new ClusterAPI();
  // #endregion API

  // #region States
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [clusterData, setClusterData] = useState<ICluster>();
  const [clusterDataLoading, setClusterDataLoading] = useState(true);

  const [clusterChartData, setClusterChartData] = useState([]);
  const [clusterChartDataLoading, setClusterChartDataLoading] = useState(true);
  const clusterChartDataRef = useRef(clusterChartData);
  const hasClusterChartData = clusterChartData.length > 0;

  const [clusterProducts, setClusterProducts] = useState<IClusterProduct[]>([]);
  const [clusterProductsLoading, setClusterProductsLoading] = useState(true);
  const productsTableRef = useRef(null);

  const [groupByDate, setGroupByDate] = useState('DAY');
  const [aggregateBy, setAggregateBy] = useState('AVG');
  const groupByDateOptions = groupByAvailableOptions();
  const aggregateByOptions = aggregateByAvailableOptions();
  const selectedGroupByDate = groupByDateOptions.filter(
    (option) => option.value === groupByDate,
  )[0];
  const selectedAggregateBy = aggregateByOptions.filter(
    (option) => option.value === aggregateBy,
  )[0];

  const [clusterChartTableData, setClusterChartTableData] = useState<
    IReportClusterDetailSites[]
  >([]);
  const [clusterChartTableLoading, setClusterChartTableLoading] =
    useState(true);
  const [chartTitle, setChartTitle] = useState(() =>
    getChartTitle(selectedAggregateBy, selectedGroupByDate),
  ); // TODO: Traduzir

  const params = useSelector((state) => state.reports.detail.params);
  const dateGte = useSelector((state) => state.reports.dateGte);
  const dateLte = useSelector((state) => state.reports.dateLte);

  const [page, setPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(null);

  // // #endregion States

  // #region Functions
  const getClustersParams = () => {
    return {
      ...params,
      group_by_date: groupByDate,
      aggregate_by: aggregateBy,
      date__gte: dateGte,
      date__lte: dateLte,
    };
  };

  useEffect(() => {
    async function getClusterData() {
      setClusterDataLoading(true);
      dispatch(setHeaderLoading(true));

      const data = await clusterApi.getCluster(groupBy, getClustersParams());

      setClusterData(data);
      setClusterDataLoading(false);

      dispatch(setHeaderTitle(data?.name));
      dispatch(
        pushHeaderSubTitle({ text: data?.name, path: location.pathname }),
      );
      dispatch(setHeaderLoading(false));
    }

    function getOffset() {
      if (productsTableRef.current && page > 1) {
        const y = productsTableRef.current.offsetTop;
        window.scrollTo(0, y);
      }
      return (page - 1) * clusterProductsLimit;
    }

    async function getClusterProducts() {
      setClusterProductsLoading(true);

      const data = await clusterApi.getProducts(groupBy, {
        ...getClustersParams(),
        limit: clusterProductsLimit,
        offset: getOffset(),
      });

      setTotalProducts(data?.count);
      setClusterProducts(data?.results);
      setClusterProductsLoading(false);
    }

    if (isFirstLoad) {
      window.scrollTo(0, 0);
      getClusterData();
      setIsFirstLoad(false);
    }

    getClusterProducts();
  }, [page]);

  useEffect(() => {
    async function getClusterChart() {
      setClusterChartDataLoading(true);

      let data = await clusterApi.getChart(groupBy, getClustersParams());
      data = _.orderBy(data, ['quantity_products'], ['desc']);
      data = setVisibleSeries(data);

      setClusterChartData(data);
      clusterChartDataRef.current = data;

      setClusterChartDataLoading(false);
    }

    getClusterChart();
  }, [dateGte, dateLte, groupByDate, aggregateBy]);

  function handleGroupBy(option) {
    setChartTitle(getChartTitle(selectedAggregateBy.value, option.value));
    setGroupByDate(option.value);
  }

  function handleAggregateBy(option) {
    setChartTitle(getChartTitle(option.value, selectedGroupByDate.value));
    setAggregateBy(option.value);
  }

  // TODO: Criar componente: Card Chart
  function getChartTitle(aggregateBy, groupBy) {
    const aggregateByKey = `components.chartAggregateByAvailableOptions.${aggregateBy}`;
    const aggregateByText = t(aggregateByKey);

    const byText = t('components.chartCard.by');

    const groupByText = t(
      `components.chartGroupByAvailableOptions.${groupBy}`,
    ).toLowerCase();

    return `${aggregateByText} ${byText} ${groupByText}`;
  }

  function setVisibleSeries(data) {
    const newClusterChartData = data.map((cluster, index) => {
      if (index < 5) {
        cluster['visible'] = true;
      } else {
        cluster['visible'] = false;
      }
      return cluster;
    });

    return newClusterChartData;
  }

  function handleOnCheckboxChange(index) {
    const chartData = clusterChartDataRef.current;
    const visibleSeries = chartData.filter(
      (serie) => serie.visible === true,
    ).length;
    const newVisibleValue = !chartData[index].visible;

    if (visibleSeries > 1 || newVisibleValue) {
      chartData[index].visible = newVisibleValue;
      setClusterChartData([...chartData]);
    }
  }

  function handleOnItemClickPagination(index) {
    setPage(index);
  }
  // #endregion functions

  // #region elements
  const groupByElement = (
    <Row className="justify-content-end">
      <Col className="d-flex flex-column justify-content-center" md="auto">
        <span className="fs-13 fw-normal text-gray-800">
          {t('components.chartGroupByAvailableOptions.groupBy')}:
        </span>
      </Col>
      <Col className="d-flex flex-column justify-content-center" md="auto">
        <div style={{ width: '100px' }}>
          <Select
            defaultValue={selectedGroupByDate}
            isDisabled={!hasClusterChartData}
            options={groupByDateOptions}
            onChange={(option) => handleGroupBy(option)}
          />
        </div>
      </Col>
      <Col className="d-flex flex-column justify-content-center" md="auto">
        <div style={{ width: '140px' }}>
          <Select
            defaultValue={selectedAggregateBy}
            isDisabled={!hasClusterChartData}
            options={aggregateByOptions}
            onChange={(option) => handleAggregateBy(option)}
          />
        </div>
      </Col>
    </Row>
  );

  // #endregion elements

  return (
    <>
      <Row className="mb-12">
        <Col>
          <ProductDetailHeader
            attributes={clusterData?.attributes}
            currency={clusterData?.currency}
            loading={clusterDataLoading}
            picture={clusterData?.picture}
            price={clusterData?.average_price}
            price_max={clusterData?.highest_price}
            price_min={clusterData?.lowest_price}
            price_variation_percent={clusterData?.price_variation_percent}
            title={clusterData?.name}
            total_products={clusterData?.quantity}
            variation_percent={clusterData?.price_variation_percent}
          />
        </Col>
      </Row>
      <Row className="mb-12">
        <Col>
          <ReportDatePickerRange
            loading={clusterChartDataLoading || clusterProductsLoading}
          />
        </Col>
      </Row>
      <Row className="mb-12">
        <Col>
          <Card
            header={chartTitle}
            headerRight={groupByElement}
            loading={clusterChartDataLoading}
          >
            <Row className="mb-12">
              <Col>
                <ReportsClusterChart
                  aggregateBy={aggregateBy}
                  data={clusterChartData}
                  loading={clusterChartDataLoading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {(clusterChartDataLoading || hasClusterChartData) && (
                  <ReportsClusterSitesTable
                    data={clusterChartData}
                    loading={clusterChartDataLoading}
                    onCheckboxChangeCallback={(index, data) =>
                      handleOnCheckboxChange(index, data)
                    }
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row ref={productsTableRef}>
        <Col>
          <Card
            header={'Produtos'} // TODO: Traduzir
            loading={clusterProductsLoading}
          >
            <ReportsClusterProductsTable
              data={clusterProducts}
              loading={clusterProductsLoading}
            />
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <Pagination
            activePage={page}
            className="center-horizontal"
            itemsPerPage={clusterProductsLimit}
            totalItems={totalProducts}
            variant="primary"
            onItemClick={handleOnItemClickPagination}
          />
        </Col>
      </Row>
    </>
  );
}
