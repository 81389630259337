import { Card } from 'components';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ReportsListTable } from 'screens/reports/components';
import { setHeaderLoading } from 'store/header';

export default function ReportPage() {
  const dispatch = useDispatch();
  dispatch(setHeaderLoading(false));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Card className="pt-10">
            <ReportsListTable />
          </Card>
        </Col>
      </Row>
    </>
  );
}
