import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumericFormat } from "react-number-format";

export default function NumberFormatArrow({ value, better = null, ...props }) {
  const greenStyle = { color: "var(--bs-success)" };
  const redStyle = { color: "var(--bs-danger)" };
  let style;
  let icon;

  if (better) {
    if (better == "positive" && value > 0) {
      style = greenStyle;
      icon = faCaretUp;
    } else if (better == "positive" && value < 0) {
      style = redStyle;
      icon = faCaretDown;
    } else if (better == "negative" && value > 0) {
      style = redStyle;
      icon = faCaretUp;
    } else if (better == "negative" && value < 0) {
      style = greenStyle;
      icon = faCaretDown;
    }
  }

  const numericComponent = <NumericFormat {...props} value={value} />;
  const iconComponent = (
    <div>
      <span style={style}>
        <FontAwesomeIcon
          className="me-2"
          style={{ verticalAlign: "middle" }}
          icon={icon}
        />
      </span>
      <NumericFormat value={value} {...props} />
    </div>
  );

  if (value == null || value == undefined) return <>-</>;
  if (props?.ifZero) {
    if (value == 0) {
      return <>{props?.ifZero}</>;
    }
  }

  return props?.arrow ? iconComponent : numericComponent;
}
