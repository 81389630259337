import { IUser } from 'api/mercadoradar/User';
import axios, { AxiosInstance } from 'axios';
import { history } from 'hooks/useNavigate';
import jwt_decode from 'jwt-decode';
import { paths } from 'paths';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export type AuthType = {
  email: string;
  password: string;
};

export const COOKIE_TOKEN = 'token';

declare interface IToken {
  access: string | null;
  refresh: string | null;
}

declare interface IForgotPassoword {
  email: string;
}

declare interface IValidateToken {
  token: string;
}

declare interface IResetPassoword {
  token: string;
  password: string;
}

export default class AuthAPI {
  baseUrl: string | undefined;
  client: AxiosInstance;
  resource: string;

  constructor() {
    this.baseUrl = process.env.REACT_APP_MERCADORADAR_API_ENDPOINT;
    this.client = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.resource = 'auth/token/';
  }

  async create(data: AuthType): Promise<IToken> {
    let token: IToken = {
      access: null,
      refresh: null,
    };

    token = await this.client
      .post(this.resource, data)
      .then((response) => {
        const token = response.data;
        const { access, refresh } = token;

        if (access) {
          cookies.set(COOKIE_TOKEN, token, { maxAge: 24 * 60 * 60 });

          if (access) return token;
        }
      })
      .catch((error) => {
        if (process.env.ENV !== 'production') console.log(error);
      });

    return token;
  }

  getSessionUser() {
    const token = cookies.get(COOKIE_TOKEN);

    if (token) {
      const access = token?.access;
      const user: IUser = jwt_decode(access);

      return user;
    } else {
      this.logOut();
    }
  }

  logOut(next?: string) {
    cookies.remove(COOKIE_TOKEN);
    return history.navigate(paths.authLogin.path, {
      searchParams: { logout: true, next },
    });
  }

  async forgotPassword(data: IForgotPassoword) {
    await this.client.post('auth/password-reset/', data);
  }

  async validateToken(data: IValidateToken) {
    try {
      const isValid = await this.client
        .post('auth/password-reset/validate_token/', data)
        .then((response) => {
          return response.status === 200;
        });

      return isValid;
    } catch {
      return false;
    }
  }

  async resetPassword(data: IResetPassoword) {
    await this.client.post('auth/password-reset/confirm/', data);
  }
}
