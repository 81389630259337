import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import className from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export type CustomColumnDef<TData, TValue> = ColumnDef<TData, TValue> & {
  accessorKey?: string;
  meta?: {
    width?: number;
    headerClassName?: string;
    cellClassName?: string;
  };
};

interface TableProps<TData> {
  data: TData[];
  columns: CustomColumnDef<TData, any>[];
  enableColumnResizing?: boolean;
  enableSorting?: boolean;
  loading?: boolean;
  state?: object;
  sorting?: SortingState;
}

function Table<TData>({
  data = [],
  columns = [],
  enableColumnResizing = false,
  enableSorting = true,
  loading = false,
  state: initialState = {},
  sorting: initialSorting = [],
  ...props
}: TableProps<TData>) {
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const memoizedData = useMemo(() => data, [JSON.stringify(data)]);
  const memoizedColumns = useMemo(
    () => columns as CustomColumnDef<TData, any>[],
    [JSON.stringify(columns)],
  );
  const columnResizeMode = 'onChange';

  const table = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    enableColumnResizing,
    enableSorting,
    columnResizeMode,
    state: {
      sorting,
      ...initialState,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const tableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    function handleResize() {
      const newTableWidth = tableRef?.current?.clientWidth;
      setTableWidth(newTableWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const newTableState = table.getState();
    const columnsCount = memoizedColumns?.length;
    if (tableWidth && columnsCount) {
      newTableState.columnSizing = memoizedColumns.reduce((object, current) => {
        const columnWidth = current?.meta?.width;
        let newColumnWidth = tableWidth / columnsCount;

        if (columnWidth) {
          newColumnWidth = columnWidth * tableWidth;
        }

        object[current.accessorKey as string] = newColumnWidth;
        return object;
      }, {});
      table.setState({ ...newTableState });
    }
  }, [tableWidth, memoizedData, memoizedColumns]);

  const skeletonHeader = (
    <Row>
      {memoizedColumns.map((_, index) => (
        <Col key={index}>
          <Skeleton height={25} width={'100%'} />
        </Col>
      ))}
    </Row>
  );

  const skeletonColumns = memoizedColumns.map((_, index) => (
    <Col key={index}>
      <Skeleton height="20" width="80%" />
    </Col>
  ));

  const skeletonTable = (
    <>
      {skeletonHeader}
      {Array(15)
        .fill()
        .map((_, index) => (
          <Row key={index} className="mt-8">
            {skeletonColumns}
          </Row>
        ))}
    </>
  );

  const tableElement = (
    <>
      <div
        ref={tableRef}
        className="react-table"
        style={{ visibility: loading ? 'hidden' : 'visible' }}
      >
        <div className="thead">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="tr">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  className={className(
                    'th',
                    header.column.getCanSort() && 'is-sortable',
                    header.column.getIsSorted(),
                    header.column.columnDef.meta?.headerClassName,
                  )}
                  style={{
                    width: header.getSize(),
                  }}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  <div
                    className={className(
                      enableColumnResizing && 'resizer',
                      header.column.getIsResizing() && 'isResizing',
                    )}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {table.getRowModel().rows.map((row) => (
            <div key={row.id} className="tr">
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  className={className(
                    'td',
                    cell.column.getIsResizing() && 'isResizing',
                    cell.column.columnDef.meta?.cellClassName,
                  )}
                  style={{
                    width: cell.getContext().column.getSize(),
                  }}
                >
                  <span>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div>
        {props?.debug && (
          <>
            <h3>Table</h3>
            <pre id="json">
              {JSON.stringify(table.getState(), undefined, 2)}
            </pre>
            <br />
            <h3>Data</h3>
            <pre id="json">{JSON.stringify(data, undefined, 2)}</pre>
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      {loading && skeletonTable}
      {tableElement}
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  enableColumnResizing: PropTypes.bool,
  enableSorting: PropTypes.bool,
  loading: PropTypes.bool,
  state: PropTypes.object,
  sorting: PropTypes.array,
};

export default Table;
