import MercadoRadarAPIClient, {
  IListAPI,
} from "api/mercadoradar/MercadoRadarAPIClient";
import { ISeller } from "api/mercadoradar/Seller";
import { SiteType } from "api/mercadoradar/Site";

const resource = "v2/product/";

export declare interface IProduct {
  id: number;
  name: string;
  url: string;
  last_price: number;
  site_name?: string;
  variation: number;
  picture: string;
  status: string;
  seller?: ISeller;
  site?: SiteType;
  path_from_root_category?: string;
  attributes?: any[];
  currency: string;
  price_updated_at?: string;
}

export declare interface IProductsResults extends IListAPI {
  results: IProduct[];
}

export declare interface IProductHistory {
  created_at: string;
  currency: string;
  id: number;
  price: number;
  status__display: string;
  status: string;
  installment_price: number;
  variation_percent: number;
  variation_price: number;
  aggregate_by: string;
}

export declare interface IProductHistoryResults extends IListAPI {
  results: IProductHistory[];
}

export declare interface IProductHistoryChart {
  x: string;
  y: number;
  status?: string;
  status__display: string;
}

export type IProductHistoryChartResults = Array<IProductHistoryChart>;

export default class ProductAPI extends MercadoRadarAPIClient {
  constructor(context = undefined) {
    super(resource, context);
  }

  async getHistory(id, params): Promise<IProductHistoryResults> {
    params = this.formatParams(params);
    const response = await this.client.get(`${this.resource}${id}/history/`, {
      params,
    });
    return await response?.data;
  }

  async getHistoryChart(id, params): Promise<IProductHistoryChartResults> {
    params = this.formatParams(params);
    const response = await this.client.get(
      `${this.resource}${id}/history/chart/`,
      { params }
    );
    return await response?.data;
  }
}
