import i18n from 'i18n';

const t = i18n.t;

const DAY = 'DAY';
const WEEK = 'WEEK';
const MONTH = 'MONTH';

function getOption(value) {
  const option = {
    value,
    label: t(`components.chartGroupByAvailableOptions.${value}`),
  };

  return option;
}

export function get(value) {
  return options().filter((element) => element.value === value)[0];
}

export function options() {
  return [getOption(DAY), getOption(WEEK), getOption(MONTH)];
}
