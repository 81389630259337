import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  attributes: [],
  categories: [],
  sellers: [],
  sites: [],
  initialCount: [],
  count: [],
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setAttribute(state, action) {
      state.attributes = action.payload;
    },

    setCategory(state, action) {
      state.categories = action.payload;
    },
    addCategory(state, action) {
      state.categories = [...state.categories, action.payload];
    },
    removeCategory(state, action) {
      state.categories = state.categories.filter(
        (item) => item !== action.payload,
      );
    },
    resetCategory(state, _) {
      state.categories = [];
    },

    setSite(state, action) {
      state.sites = action.payload;
    },
    setSeller(state, action) {
      state.sellers = action.payload;
    },
    setInitialCount(state, action) {
      state.initialCount = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },

    resetFilters(state) {
      state.attributes = [];
      state.categories = [];
      state.sellers = [];
      state.sites = [];
      state.initialCount = state.initialCount.map((_) => 0);
      state.count = state.count.map((_) => 0);
    },
  },
});

export const {
  setCategory,
  addCategory,
  removeCategory,
  resetCategory,

  setSite,
  setSeller,
  setAttribute,
  setInitialCount,
  setCount,

  resetFilters,
} = filtersSlice.actions;
export default filtersSlice.reducer;
