const basePath = "/auth";
const subTitle = ["Mercado Radar"];
const auth = false;

const base = {
  auth,
  subTitle,
};

export default {
  authLogin: {
    ...base,
    path: `${basePath}/login`,
    title: "Que bom que você voltou",
    sideNavVisible: false,
  },
  authCreate: {
    ...base,
    path: `${basePath}/create`,
    title: "Criar conta",
    sideNavVisible: false,
  },
  authForgotPassword: {
    ...base,
    path: `${basePath}/forgotPassword`,
    title: "Esqueceu sua senha?",
    sideNavVisible: false,
  },
};
