import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18n from 'i18n';
import _ from 'lodash';
import { Cookies } from 'react-cookie';
import { AttributeList } from 'types/Attribute';
import { AttributeType, CATEGORY, SELLER, SITE } from 'types/AttributeType';
import { Category, CategoryList } from 'types/Category';
import { ProductList } from 'types/Product';
import {
  Report,
  ReportAverageProductPrice,
  ReportList,
  ReportTotalProducts,
} from 'types/Report';
import { SellerList } from 'types/Seller';
import { SiteList } from 'types/Site';

const FIVE_MINUTES = 5 * 60;
const ONE_YEAR = 365 * 24 * 60 * 60;

const cookies = new Cookies();
export const COOKIE_TOKEN = 'token';

const t = i18n.t;

type Pagination = {
  limit?: number;
  offset?: number;
};

type ProductParams = {
  attributes: Array<number>;
  categories: Array<string>;
  sites: Array<number>;
  sellers: Array<number>;
} & Pagination;

type ReportParams = ProductParams & {
  groupBy: Array<string>;
  dateGte?: string;
  dateLte?: string;
  comparisonDateGte?: string;
  comparisonDateLte?: string;
};

export const logout = () => {
  cookies.remove(COOKIE_TOKEN);
  window.location.href = '/';
};

export const MercadoRadarAPI = createApi({
  reducerPath: 'MercadoRadarAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MERCADORADAR_API_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = cookies.get(COOKIE_TOKEN);
      const accessToken = token?.access;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  tagTypes: [
    'attribute',
    'attributeType',
    'category',
    'categoryRetrieve',
    'product',
    'report',
    'reportTotalProducts',
    'reportAverageProductPrice',
    'site',
    'seller',
  ],
  endpoints: (builder) => ({
    attributeList: builder.query<
      AttributeList,
      {
        attributeType: string;
        search?: string;
        limit?: number;
        offset?: number;
      }
    >({
      query: ({ attributeType, search, limit = 10, offset = 0 }) => ({
        url: `/v2/attribute/${attributeType}/`,
        params: { search, limit, offset },
      }),
      transformResponse: (
        response: AttributeList,
        meta,
        arg: { attributeType: string; offset?: number },
      ) => {
        const attributeType = arg.attributeType;
        const translateAttributeTypes = [
          'COLOR',
          'GENDER',
          'PACKAGE_TYPE',
          'IS_KIT',
          'IS_KIT_SAME_PRODUCT',
        ];
        let attributes;

        if (translateAttributeTypes.includes(attributeType)) {
          attributes = [
            ...response.results.map((item) => ({
              ...item,
              text: t(
                `attributes.${attributeType}.${item.value.toLocaleUpperCase()}`,
              ),
            })),
          ];
          attributes = _.sortBy(attributes, ['text']);
        } else {
          attributes = [
            ...response.results.map((item) => ({ ...item, text: item.value })),
          ];
        }

        return { ...response, results: attributes };
      },
      providesTags: ['attribute'],
    }),

    attributeTypeList: builder.query<AttributeType[], void>({
      query: () => '/v2/attribute-type/',
      transformResponse: (response: AttributeType[]) => {
        const attributeType = [
          { name: CATEGORY, text: t('attributeTypes.CATEGORY') },
          { name: SITE, text: t('attributeTypes.SITE') },
          { name: SELLER, text: t('attributeTypes.SELLER') },
          ...response.map((item) => ({
            ...item,
            text: t(`attributeTypes.${item.name}`),
          })),
        ];

        return _.sortBy(attributeType, ['text']);
      },
      providesTags: ['attributeType'],
    }),

    categoryList: builder.query<
      CategoryList,
      { search?: string; limit?: number; offset?: number }
    >({
      query: ({ search = '', limit = 10, offset = 0 }) => ({
        url: '/v2/category/',
        params: { search, limit, offset },
      }),
      transformResponse: (response: CategoryList) => {
        const category = response?.results.map((item) => ({
          ...item,
          text: item.name,
          hasChildren: true,
        }));
        return { ...response, results: _.sortBy(category, ['text']) };
      },
      providesTags: ['category'],
    }),
    categoryRetrieve: builder.query<Category, Pick<Category, 'id'>>({
      query: ({ id }) => ({
        url: `/v2/category/${id}/`,
      }),
      providesTags: ['categoryRetrieve'],
    }),

    productList: builder.query<ProductList, ProductParams>({
      query: ({
        attributes,
        categories,
        sites,
        sellers,
        limit = 48,
        offset = 0,
      }) => ({
        url: '/v2/product/',
        params: {
          attributes_ids: attributes,
          categories_ids: categories,
          sites_ids: sites,
          sellers_ids: sellers,
          limit,
          offset,
        },
      }),
      providesTags: ['product'],
    }),

    reportCreate: builder.mutation<Report, Report>({
      query: (data) => ({
        url: '/v2/report/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['report'],
    }),
    reportList: builder.query<ReportList, Pagination>({
      query: ({ limit = 10, offset = 0 }) => ({
        url: '/v2/report/',
        params: { limit, offset },
      }),
      providesTags: ['report'],
    }),
    reportRetrieve: builder.query<Report, Pick<Report, 'id'>>({
      query: ({ id }) => ({
        url: `/v2/report/${id}/`,
      }),
      providesTags: ['report'],
    }),
    reportUpdate: builder.mutation<Report, Pick<Report, 'id'>>({
      query: ({ id, ...data }) => ({
        url: `/v2/report/${id}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['report'],
    }),
    reportDelete: builder.mutation<Report, Pick<Report, 'id'>>({
      query: ({ id }) => ({
        url: `/v2/report/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['report'],
    }),

    reportTotalProducts: builder.query<ReportTotalProducts, ReportParams>({
      query: ({
        groupBy,
        attributes,
        categories,
        sites,
        sellers,
        dateGte,
        dateLte,
        comparisonDateGte,
        comparisonDateLte,
      }) => ({
        url: '/v2/report/total_products/',
        params: {
          group_by: groupBy,
          attributes_ids: attributes,
          categories_ids: categories,
          sites_ids: sites,
          sellers_ids: sellers,
          date__gte: dateGte,
          date__lte: dateLte,
          comparison_date__gte: comparisonDateGte,
          comparison_date__lte: comparisonDateLte,
        },
      }),
      providesTags: ['reportTotalProducts'],
    }),
    reportAverageProductPrice: builder.query<
      ReportAverageProductPrice,
      ReportParams
    >({
      query: ({
        groupBy,
        attributes,
        categories,
        sites,
        sellers,
        dateGte,
        dateLte,
        comparisonDateGte,
        comparisonDateLte,
      }) => ({
        url: '/v2/report/average_product_price/',
        params: {
          group_by: groupBy,
          attributes_ids: attributes,
          categories_ids: categories,
          sites_ids: sites,
          sellers_ids: sellers,
          date__gte: dateGte,
          date__lte: dateLte,
          comparison_date__gte: comparisonDateGte,
          comparison_date__lte: comparisonDateLte,
        },
      }),
      providesTags: ['reportAverageProductPrice'],
    }),
    reportCluster: builder.query<ReportAverageProductPrice, ReportParams>({
      query: ({
        groupBy,
        attributes,
        categories,
        sites,
        sellers,
        dateGte,
        dateLte,
        comparisonDateGte,
        comparisonDateLte,
      }) => ({
        url: '/v2/report/cluster/',
        params: {
          group_by: groupBy,
          attributes_ids: attributes,
          categories_ids: categories,
          sites_ids: sites,
          sellers_ids: sellers,
          date__gte: dateGte,
          date__lte: dateLte,
          comparison_date__gte: comparisonDateGte,
          comparison_date__lte: comparisonDateLte,
        },
      }),
      providesTags: ['reportAverageProductPrice'],
    }),

    siteList: builder.query<
      SiteList,
      { search?: string; limit?: number; offset?: number }
    >({
      query: ({ search, limit = 10, offset = 0 }) => ({
        url: '/v2/site/',
        params: { search, limit, offset },
      }),
      transformResponse: (response: SiteList) => {
        const site = response?.results.map((item) => ({
          ...item,
          text: item.name,
        }));
        return { ...response, results: _.sortBy(site, ['text']) };
      },
      providesTags: ['site'],
    }),

    sellerList: builder.query<
      SellerList,
      { search?: string; limit?: number; offset?: number }
    >({
      query: ({ search, limit = 10, offset = 0 }) => ({
        url: '/v2/seller/',
        params: { search, limit, offset },
      }),
      transformResponse: (response: SellerList) => {
        const site = response?.results.map((item) => ({
          ...item,
          text: `${item.name} (${item.site__name})`,
        }));
        return { ...response, results: _.sortBy(site, ['text']) };
      },
      providesTags: ['seller'],
    }),
  }),
});

export const {
  useAttributeListQuery,
  useAttributeTypeListQuery,

  useCategoryListQuery,
  useCategoryRetrieveQuery,

  useProductListQuery,
  useSiteListQuery,
  useSellerListQuery,

  useReportCreateMutation,
  useReportListQuery,
  useReportRetrieveQuery,
  useReportUpdateMutation,
  useReportDeleteMutation,

  useReportTotalProductsQuery,
  useReportAverageProductPriceQuery,
  useReportClusterQuery,
} = MercadoRadarAPI;
