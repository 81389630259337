import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { MercadoRadarAPI } from 'api/mercadoradar/MercadoRadarAPIClientV2';
import filtersReducer from 'store/filtersSlice';
import headerSliceReducer from 'store/header';
import reportsReducer from 'store/reportsSlice';

export const store = configureStore({
  reducer: {
    [MercadoRadarAPI.reducerPath]: MercadoRadarAPI.reducer,
    filters: filtersReducer,
    reports: reportsReducer,
    header: headerSliceReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(MercadoRadarAPI.middleware),
});

setupListeners(store.dispatch);
