import IAttribute from "api/mercadoradar/Attribute";
import MercadoRadarAPIClient from "api/mercadoradar/MercadoRadarAPIClient";
import { ISeller } from "api/mercadoradar/Seller";

const resource = "v2/cluster/";

// #region types
export declare interface ICluster {
  name: string;
  attributes: IAttribute[];
  quantity: number;
  average_price: number;
  lowest_price: number;
  highest_price: number;
  variation: number;
}

export declare interface IClusterChartPoint {
  x: string;
  y: number;
}

export declare interface IClusterChart {
  id: number;
  name: string;
  quantity_products: number;
  quantity_products_percent: number;
  average_price: number;
  average_price_variation: number;
  average_price_variation_percent: number;
  average_total_installment_price: number;
  data: IClusterChartPoint[];
}

export declare interface IClusterProduct {
  id: number;
  picture: string;
  name: string;
  site__name: string;
  seller: ISeller;
  status: string;
  history_updated_at: string;
  price: number;
  price_variation: number;
  price_variation_percent: number;
  installment_price: number;
}

export declare interface IListClusterProduct {
  count: 0,
  next: null,
  previous: null,
  results: IClusterProduct[],
}
// #endregion types

export default class ClusterAPI extends MercadoRadarAPIClient {
  constructor() {
    super(resource);
  }

  async getCluster(groupBy, params): Promise<ICluster> {
    params = this.formatParams(params);
    const response = await this.client.get(`${this.resource}${groupBy}/`, {
      params,
    });
    const data: ICluster = await response.data;

    return data;
  }

  async getChart(groupBy, params): Promise<IClusterChart[]> {
    params = this.formatParams(params);
    const response = await this.client.get(
      `${this.resource}${groupBy}/chart/`,
      {
        params,
      }
    );
    const data: IClusterChart[] = await response.data;

    return data;
  }

  async getProducts(groupBy, params): Promise<IListClusterProduct> {
    params = this.formatParams(params);
    const response = await this.client.get(
      `${this.resource}${groupBy}/product/`,
      {
        params,
      }
    );

    const data: IListClusterProduct = await response.data;

    return data;
  }
}
