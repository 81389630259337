import i18n from 'i18n';

const t = i18n.t;

const MIN = 'MIN';
const AVG = 'AVG';
const MAX = 'MAX';

function getOption(value) {
  const option = {
    value,
    label: t(`components.chartAggregateByAvailableOptions.${value}`),
  };

  return option;
}

export function get(value) {
  return options().filter((element) => element.value === value)[0];
}

export function options() {
  return [getOption(MIN), getOption(AVG), getOption(MAX)];
}
