import { Card } from 'components';
import Skeleton from 'react-loading-skeleton';

export default function ProductCardSkeleton() {
  return (
    <Card>
      <div className="pb-2">
        <Skeleton className="mb-4" height={203} width={'100%'} />
        <span className="d-block fs-15 pb-2" style={{ minHeight: '51px' }}>
          <Skeleton height={25} />
          <Skeleton height={25} width={'75%'} />
        </span>
        <span className="d-block text fs-13 pb-2">
          <Skeleton height={25} width={'50%'} />
        </span>
        <span className="d-block fs-22 text fw-bolder mb-4">
          <Skeleton height={25} width={'50%'} />
        </span>
        <span className="d-block fs-12 text">
          <Skeleton height={18} width={'66%'} />
        </span>
      </div>
    </Card>
  );
}
