import { ExportAPI } from 'api/mercadoradar';
import { useProductListQuery } from 'api/mercadoradar/MercadoRadarAPIClientV2';
import { DateTime } from 'luxon';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { resetFilters } from 'store/filtersSlice';
import { setHeaderLoading } from 'store/header';
import { resetGroupBy } from 'store/reportsSlice';

const NumberFormatArrow = lazy(() => import('components/NumberFormatArrow'));
const Pagination = lazy(() => import('components/Pagination'));
const Sidebar = lazy(() => import('components/Sidebar'));
const Spinner = lazy(() => import('components/Spinner'));
const ProductsGrid = lazy(
  () => import('screens/products/components/ProductsGrid'),
);

export default function ProductPage() {
  const exportApi = new ExportAPI();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productsLimit = 48;
  const [page, setPage] = useState(1);
  const offset = (page - 1) * productsLimit;
  dispatch(setHeaderLoading(false));

  // #region states
  const [queryParams, setQueryParams] = useState({});
  const params = queryParams ? { ...queryParams, offset } : null;
  const { data: products, isFetching } = useProductListQuery(params);
  // #endregion states

  // #region functions
  const [isExporting, setIsExporting] = useState(false);

  async function handleOnClickExport() {
    setIsExporting(true);

    const date = DateTime.now().minus({ days: 1 }).toISODate();
    await exportApi.getHistoryByDate(date);

    setIsExporting(false);
  }

  function handleOnItemClickPagination(index) {
    setPage(index);
  }

  const handleOnApply = (e) => {
    setQueryParams(e);
    window.scrollTo(0, 0);
  };
  // #endregion functions

  useEffect(() => {
    window.scrollTo(0, 0);
    setPage(1);
    dispatch(resetFilters());
    dispatch(resetGroupBy());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const totalProductsText = !isFetching ? (
    <Suspense>
      <NumberFormatArrow
        fixedDecimalScale
        decimalScale={0}
        decimalSeparator=","
        displayType="text"
        format={'0'}
        suffix={` ${t('screens.products.productsFound', { count: products?.count })}`}
        thousandSeparator="."
        value={products?.count}
      />
    </Suspense>
  ) : (
    <Skeleton height={17} width={'15%'} />
  );

  return (
    <>
      <Row className="pb-16">
        <Col md={4} xxl={3}></Col>
        <Col
          className="d-flex justify-content-between align-items-center"
          md={8}
          xxl={9}
        >
          <span className="fs-13 text center-horizontal">
            {totalProductsText}
          </span>

          <Button
            disabled={isExporting || isFetching}
            variant="outline-primary"
            onClick={handleOnClickExport}
          >
            <Suspense>
              {isExporting && <Spinner size="sm" />}
              {!isExporting && (
                <span>{t('components.sideBar.buttons.export')}</span>
              )}
            </Suspense>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={4} xxl={3}>
          <Suspense>
            <Sidebar
              isFetching={isFetching}
              offset={offset}
              onApply={handleOnApply}
            />
          </Suspense>
        </Col>

        <Col md={8} xxl={9}>
          <Row>
            <Suspense>
              <ProductsGrid loading={isFetching} products={products?.results} />
            </Suspense>
          </Row>
          <Row className="mt-2">
            <Col md={12}>
              <Suspense>
                <Pagination
                  activePage={page}
                  className="center-horizontal"
                  itemsPerPage={productsLimit}
                  totalItems={products?.count}
                  variant="primary"
                  onItemClick={handleOnItemClickPagination}
                />
              </Suspense>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
