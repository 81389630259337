import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAttribute, setSeller, setSite } from "store/filtersSlice";
import { SELLER, SITE } from "types/AttributeType";

export default function ReportsFiltersCollapseListCheckbox({ name, text, value, onChange }) {
  const dispatch = useDispatch();
  const attributes = useSelector((state) => state.filters.attributes); 
  const sites = useSelector((state) => state.filters.sites); 
  const sellers = useSelector((state) => state.filters.sellers); 

  const isChecked = attributes.includes(value) || sites.includes(value) || sellers.includes(value);

  const handleOnClick = (isChecked) => {
    if (onChange) {
      onChange(isChecked);
    }
    
    if (name === SITE) {
      if (isChecked) {
        dispatch(setSite([...sites, value]));
      } else {
        dispatch(setSite(sites.filter(item => item !== value)));
      }
    } else if (name === SELLER) {
      if (isChecked) {
        dispatch(setSeller([...sellers, value]));
      } else {
        dispatch(setSeller(sellers.filter(item => item !== value)));
      }
    } else {
      if (isChecked) {
        dispatch(setAttribute([...attributes, value]));
      } else {
        dispatch(setAttribute(attributes.filter(item => item !== value)));
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    handleOnClick(isChecked);
  };

  return (
    <Checkbox 
      checked={isChecked}
      onChange={handleCheckboxChange}
      title={text}>
      <span 
        style={{
          display: "inline-block",
          maxWidth: "297px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          verticalAlign: "middle",
        }} 
        title={text}>
        {text}
      </span>
    </Checkbox>
  );
}
