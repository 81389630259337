import { Tree, TreeDataNode } from 'antd';
import {
  useCategoryListQuery,
  useCategoryRetrieveQuery,
} from 'api/mercadoradar/MercadoRadarAPIClientV2';
import Spinner from 'components/Spinner';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { setCategory } from 'store/filtersSlice';

export default function CollapseTree({ onChange }) {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.filters.categories);

  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [key, setKey] = useState<string | null>(null);

  const queryHookParam = key ? { id: key } : {};
  const queryHook = key ? useCategoryRetrieveQuery : useCategoryListQuery;
  const { data, isFetching } = queryHook(queryHookParam);

  const updateTreeData = (
    list: TreeDataNode[],
    key: string,
    children: TreeDataNode[],
  ): TreeDataNode[] => {
    return list.map((node) => {
      if (node.key === key) {
        return { ...node, children };
      } else if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  };

  const onLoadData = ({ key, children }: any) => {
    setKey(key);
    return Promise.resolve();
  };

  const onCheck = (checkedKeys: any, info: any) => {
    dispatch(setCategory(checkedKeys));
    if (onChange) {
      onChange(checkedKeys.length);
    }
  };

  useEffect(() => {
    if (data?.results && !key) {
      const newTreeData = data.results.map((item) => ({
        title: item.name,
        key: item.id,
        isLeaf: !item.hasChildren,
      }));
      return setTreeData(newTreeData);
    }

    if (data && key) {
      const newTreeData = data.children_categories.map((item) => ({
        title: item.name,
        key: item.id,
        isLeaf: !item.has_children_categories,
      }));
      return setTreeData((prevTreeData) =>
        updateTreeData(prevTreeData, key, newTreeData),
      );
    }
  }, [data]);

  const renderTreeNodes = (nodes: TreeDataNode[]): React.ReactNode =>
    nodes.map((node) => {
      if (node.children) {
        return (
          <Tree.TreeNode key={node.key} title={node.title}>
            {renderTreeNodes(node.children)}
          </Tree.TreeNode>
        );
      }
      return (
        <Tree.TreeNode
          key={node.key}
          isLeaf={node.isLeaf}
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="me-2">{node.title}</span>
              {isFetching && key === node.key && <Spinner size="small" />}
            </div>
          }
        />
      );
    });

  const skeletonArray = Array.from({ length: 5 }, (_, idx) => ({
    key: `skeleton-item-${idx}`,
  }));

  const skeleton = skeletonArray.map((item) => (
    <Skeleton key={item.key} height="22px" width="300px" />
  ));

  const tree = (
    <Tree
      checkable
      checkedKeys={categories}
      expandedKeys={expandedKeys}
      loadData={onLoadData}
      selectable={false}
      treeData={treeData}
      onCheck={onCheck}
      onExpand={(keys) => setExpandedKeys(keys as string[])}
    >
      {renderTreeNodes(treeData)}
    </Tree>
  );

  return isFetching && !treeData.length ? skeleton : tree;
}
