import AuthAPI from 'api/mercadoradar/Auth';
import Logo from 'assets/images/logo-color.svg';
import { Box, BarChart, Settings, ChevronDown } from 'feather-icons-react';
import { paths } from 'paths';
import { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { Collapse, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Sidenav = ({ ...props }) => {
  const authApi = new AuthAPI();
  const location = useLocation();
  const { t } = useTranslation();
  const sessionUser = authApi.getSessionUser();

  const [activeItemId, setActiveItemId] = useState(() => {
    const activeItemId = Object.keys(paths).find((itemId) => {
      const itemPath = paths[itemId].path;
      return location.pathname.startsWith(itemPath);
    });
    return activeItemId;
  });

  const icons = {
    box: <Box size="20" />,
    'bar-chart': <BarChart size="20" />,
    settings: <Settings size="20" />,
  };

  useEffect(() => {
    const activeItemId = Object.keys(paths).find((itemId) => {
      const itemPath = paths[itemId].path;
      return location.pathname.startsWith(itemPath);
    });
    setActiveItemId(activeItemId);
  }, [location.pathname]);

  const isItemActive = (item) => location.pathname.startsWith(item.path);

  const isExpanded = (itemId) => activeItemId === itemId || isParent(itemId);

  const isParent = (itemId) => {
    const item = paths[itemId];
    if (!item?.children) {
      return false;
    }
    if (item.children.includes(activeItemId)) {
      return true;
    }
    return item.children.some((childId) => isParent(childId));
  };

  const getItems = (ids) =>
    ids.map((id, index) => {
      const item = paths[id];
      const visible = item?.sideNavVisible ?? true;
      if (visible) {
        return (
          <div key={id}>
            {index > 0 && <hr className="navbar-divider" />}
            {item.title && <h6 className="navbar-heading">{item.title}</h6>}
            {item.children && <Nav>{getSubitems(item.children, id, ids)}</Nav>}
          </div>
        );
      }
      return null;
    });

  const getSubitems = (ids, parentId, arr) =>
    ids.map((id) => {
      const index = arr.indexOf(id);
      const item = paths[id];
      if (index !== -1) arr.splice(index, 1);
      const title = t(`components.sideNav.${item.id}`);
      return item ? (
        <Nav.Item key={id}>
          {item.children ? (
            <>
              <Nav.Link role="button" onClick={() => handleClick(id, parentId)}>
                {item.icon && icons[item.icon]}
                {title}
                <ChevronDown
                  className={`ms-auto nav-chevron ${isExpanded(id) && 'active'}`}
                  size="1.2em"
                  {...props}
                />
              </Nav.Link>
              <Collapse in={isExpanded(id)}>
                <div className="nav nav-sm flex-column">
                  {getSubitems(item.children, id, arr)}
                </div>
              </Collapse>
            </>
          ) : (
            <Nav.Link
              active={isItemActive(item)}
              as={Link}
              className="nav nav-bar"
              to={item.path}
              onClick={() => handleClick(id, parentId)}
            >
              {item.icon && icons[item.icon]}
              {title}
            </Nav.Link>
          )}
        </Nav.Item>
      ) : null;
    });

  const handleClick = (itemId, parentId, setVisible) => {
    setActiveItemId(isExpanded(itemId) ? parentId : itemId);
    if (setVisible) {
      setVisible(false);
    }
  };

  const handleLogOut = async () => {
    authApi.logOut();
  };

  const toggler = <Navbar.Toggle />;

  const brand = (
    <Link to={paths.product.path}>
      <Navbar.Brand>
        <img className="navbar-brand-img" loading="lazy" src={Logo} />
      </Navbar.Brand>
    </Link>
  );

  const user = (
    <Dropdown align="end" className="d-md-none">
      <Dropdown.Toggle
        className="bg-transparent"
        role="button"
        size="sm"
        style={{ border: 'none' }}
      >
        <Avatar
          color="var(--bs-blue)"
          name={sessionUser?.name}
          round={true}
          size="44"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>
          <strong className="d-block">{sessionUser?.name}</strong>
          <span className="d-block">{sessionUser?.email}</span>
        </Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogOut}>
          {t('components.sideNav.logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const footer = (
    <div className="navbar-user d-none d-md-flex">
      <Dropdown drop="up">
        <Dropdown.Toggle
          className="bg-transparent"
          role="button"
          size="sm"
          style={{ border: 'none' }}
        >
          <Avatar
            color="var(--bs-blue)"
            name={sessionUser?.name}
            round={true}
            size="44"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>
            <strong className="d-block">{sessionUser?.name}</strong>
            <span className="d-block">{sessionUser?.email}</span>
          </Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogOut}>
            {t('components.sideNav.logout')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const collapse = (
    <Navbar.Collapse {...props}>
      {getItems(Object.keys(paths))}
      <div className="mt-auto mb-md-4" />
      {footer}
    </Navbar.Collapse>
  );

  return (
    <Navbar
      collapseOnSelect
      className="navbar-vertical fixed-start"
      expand="md"
      {...props}
    >
      <Container fluid>
        {toggler}
        {brand}
        {user}
        {collapse}
      </Container>
    </Navbar>
  );
};

export default Sidenav;
