import { Attribute } from "types/Attribute";

export const CATEGORY = "CATEGORY";
export const SITE = "SITE";
export const SELLER = "SELLER";
export const ATTRIBUTES = "ATTRIBUTES";

export type AttributeType = {
    id: number;
    name: string;
    text?: string;
    isFetching?: boolean;
    data?: Array<Attribute>;
}