import { createColumnHelper } from '@tanstack/react-table';
import {
  NumberFormatArrow,
  ProgressBarPositiveNegative,
  Table,
} from 'components';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setDetail } from 'store/reportsSlice';
import { AverageProductPriceGroups } from 'types/Report';

export default function ReportsAveragePriceTable({
  groupName,
  data,
  currency,
  loading,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function getMaxAveragePriceVariation() {
    const maxValue = _.maxBy(
      data,
      (element) => element?.average_price_variation,
    );
    return maxValue?.average_price_variation;
  }

  function getMinAveragePriceVariation() {
    const minValue = _.minBy(
      data,
      (element) => element?.average_price_variation,
    );
    return minValue?.average_price_variation;
  }

  const maxVariation = Math.max(
    getMaxAveragePriceVariation(),
    -1 * getMinAveragePriceVariation(),
  );

  const handleOnClick = (groupBy, params) => {
    dispatch(setDetail({ groupBy, params }));
  };

  const columnHelper = createColumnHelper<AverageProductPriceGroups>();
  const enableColumnResizing = true;
  const enableSorting = true;

  const columns = [
    columnHelper.accessor('value', {
      header: groupName,
      meta: {
        width: 0.4,
      },
      cell: ({ cell, row }) => {
        const groupBy = row.original.endpoint_parameters.path_param;
        const queryParams = row.original.endpoint_parameters.temp_query_params;
        return (
          <Link
            to="/report/detail"
            onClick={() => handleOnClick(groupBy, queryParams)}
          >
            {cell.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor('average_price', {
      header: t('components.tables.averagePrice'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          prefix={`${currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('average_price_variation', {
      header: t('components.tables.averagePriceVariation'),
      meta: {
        width: 0.4,
      },
      cell: (cell) => (
        <Row>
          <Col className="text-end" md={4} xl={3} xxl={2}>
            <NumberFormatArrow
              fixedDecimalScale
              decimalScale={1}
              decimalSeparator=","
              displayType="text"
              format={'0.0'}
              suffix="%"
              thousandSeparator="."
              value={cell.getValue() * 100}
            />
          </Col>
          <Col>
            <ProgressBarPositiveNegative
              max={maxVariation}
              min={-1 * maxVariation}
              now={cell.getValue()}
              style={{ margin: 'auto' }}
            />
          </Col>
        </Row>
      ),
    }),
    columnHelper.accessor('average_price_previous_period_variation', {
      header: t('components.tables.variationPercent'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          arrow={true}
          better="positive"
          decimalScale={1}
          decimalSeparator=","
          displayType="text"
          format={'0.0'}
          ifZero={'-'}
          suffix="%"
          thousandSeparator="."
          value={100 * cell.getValue()}
        />
      ),
    }),
  ];
  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    enableSorting,
    loading,
    responsive: true,
    onRowClick: null,
  };

  return <Table {...tableProps} />;
}
