import { DatePicker } from 'components';
import { ChevronDown } from 'feather-icons-react';
import { DateTime } from 'luxon';
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDateGte, setDateLte } from 'store/reportsSlice';

export default function ReportDatePickerRange({
  comparison = true,
  handleCallback = undefined,
  loading,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dateGte = useSelector((state) => state.reports.dateGte);
  const dateLte = useSelector((state) => state.reports.dateLte);
  const comparisonDateGte = useSelector(
    (state) => state.reports.comparisonDateGte,
  );
  const comparisonDateLte = useSelector(
    (state) => state.reports.comparisonDateLte,
  );

  const datePickerRef = useRef(null);
  const yesterday = DateTime.now().minus({ days: 1 });

  function handleDatePickerOnChange(values = [null, null]) {
    const dateGteValue = values[0];
    const dateLteValue = values[1];

    if (!dateGteValue || !dateLteValue) return;

    dispatch(setDateGte(dateGteValue));
    dispatch(setDateLte(dateLteValue));

    if (handleCallback)
      handleCallback({
        dateGteValue,
        dateLteValue,
        comparisonDateGte,
        comparisonDateLte,
      });
  }

  function handleOpenDatePicker() {
    if (!datePickerRef?.current?.flatpickr) return;
    datePickerRef.current.flatpickr.open();
  }

  function parseDate(text) {
    const date = DateTime.fromISO(text).toJSDate();
    return date;
  }

  function formatDate(date, format) {
    if (date == null) return;
    return DateTime.fromISO(date).toFormat(format);
  }

  return (
    <Row style={{ minHeight: '20px' }}>
      <Col md={12}>
        <Row className="justify-content-end">
          <Col md="auto">
            <Row>
              <Col className="ms-4 g-0 fs-13 me-2" md="auto">
                <span className="fs-4 text text-gray-800">
                  {loading ? (
                    <Skeleton width={50} />
                  ) : (
                    `${t('components.datePicker.period')}:`
                  )}
                </span>
              </Col>
              <Col className="g-0 me-5 fs-13 fw-700" md="auto">
                {loading ? (
                  <Skeleton width={190} />
                ) : (
                  <DatePicker
                    ref={datePickerRef}
                    options={{
                      mode: 'range',
                      dateFormat: t('components.datePicker.format'),
                      maxDate: yesterday.toISODate(),
                      allowInput: false,
                      wrap: true,
                      parseDate: (text) => {
                        return parseDate(text);
                      },
                    }}
                    style={{ cursor: 'pointer' }}
                    value={[dateGte, dateLte]}
                    onChange={(values) => {
                      handleDatePickerOnChange(values);
                    }}
                  >
                    <input
                      data-input
                      className="datepicker d-inline-block fs-4 text text-primary fw-700"
                      style={{
                        textAlign: 'right',
                        width: '170px',
                      }}
                      type="text"
                    />
                    <a
                      data-toggle
                      className="input-button ms-6"
                      onClick={() => handleOpenDatePicker}
                    >
                      <ChevronDown className="input-button" size={14} />
                    </a>
                  </DatePicker>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {comparison && (
          <Row className="justify-content-end">
            <Col className="pe-15" md="auto">
              <span className="fs-12 text text-gray-800">
                {loading ? (
                  <Skeleton width={250} />
                ) : (
                  <div>
                    {`${t('components.datePicker.comparedTo')} `}
                    {formatDate(
                      comparisonDateGte,
                      t('components.datePicker.formatLuxon'),
                    )}
                    {comparisonDateGte !== comparisonDateLte && (
                      <>
                        <span>{` ${t('components.datePicker.to')} `}</span>
                        <span>
                          {formatDate(
                            comparisonDateLte,
                            t('components.datePicker.formatLuxon'),
                          )}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </span>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
