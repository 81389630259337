import 'assets/styles/nprogress.css';
import { Sidenav } from 'components';
import NProgress from 'nprogress';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from 'components';

export default function PrivateLayout() {
  const [prevLoc, setPrevLoc] = useState('');
  const location = useLocation();
  const nProgress = NProgress.configure({ showSpinner: false });

  useEffect(() => {
    setPrevLoc(location.pathname);
    nProgress.start();
    if (location.pathname === prevLoc) {
      setPrevLoc('');
    }
  }, [location]);

  useEffect(() => {
    nProgress.done();
  }, [prevLoc]);

  return (
    <>
      <Sidenav />
      <div className="main-content">
        <Container fluid className="pb-32">
          <Row>
            <Col>
              <Header />
              {/* <Header {...pageProps} /> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
