import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import * as localeEn from "flatpickr/dist/l10n/default";
import * as localePt from "flatpickr/dist/l10n/pt";
import { I18N_COOKIE, I18N_LOCAL_STORAGE } from "i18n";
import { Cookies } from "react-cookie";
import Flatpickr from "react-flatpickr";

const cookies = new Cookies();

const i18nCookie = cookies.get(I18N_COOKIE);
const i18nLocalStorage  = localStorage.getItem(I18N_LOCAL_STORAGE);
let currentLocale = i18nCookie || i18nLocalStorage;

currentLocale = currentLocale.split("-")[0];

const locales = {
  "en": localeEn.english,
  "pt": localePt.Portuguese
};


flatpickr.localize(locales[currentLocale]);


const DatePicker = Flatpickr;

export default DatePicker;
