import { createColumnHelper } from '@tanstack/react-table';
import { paths } from 'paths';
import { Table } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ReportsListTable = ({ data }) => {
  type ReportsList = {
    id: number;
    name: string;
    action: any;
  };

  data = [
    { id: 1, name: 'Relatório exemplo 001', action: 1 },
    { id: 2, name: 'Relatório exemplo 002', action: 1 },
    { id: 3, name: 'Relatório exemplo 003', action: 1 },
  ];

  const { t } = useTranslation();

  const columnHelper = createColumnHelper<ReportsList>();
  const enableColumnResizing = true;
  const columns = [
    columnHelper.accessor('name', {
      meta: {
        headerClassName: 'd-none',
      },
      cell: (cell) => (
        <>
          <a
            className="d-block"
            href={`${paths.reportEdit.path}/?group_by=BRAND,COLOR`} // TODO: CRUD DE REPORTS
          >
            {cell.getValue()}
          </a>
          <span className="text-gray-800">
            {t('screens.reports.createdAt')} 01/01/2023
          </span>
        </>
      ),
    }),
    columnHelper.accessor('action', {
      meta: {
        headerClassName: 'd-none',
      },
      cell: () => {
        <span>Teste</span>;
      },
    }),
  ];
  const tableProps = {
    columns,
    data,
    enableColumnResizing,
  };

  return <Table {...tableProps} />;
};

ReportsListTable.propTypes = {
  data: PropTypes.object,
};

export default ReportsListTable;
