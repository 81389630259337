import { ProgressBar } from "react-bootstrap";

export default function ProgressBarPositiveNegative({
  now,
  max,
  min,
  positiveVariant = "primary",
  negativeVariant = "danger",
  style = {},
}) {
  const negativeMin = -1 * min;

  let negativeValue;
  let positiveValue;

  if (now < 0) {
    negativeValue = -1 * now;
    positiveValue = 0;
  } else if (now > 0) {
    negativeValue = 0;
    positiveValue = now;
  }

  const negativeProgressBar = (
    <ProgressBar
      variant={negativeVariant}
      now={negativeValue}
      max={negativeMin}
      min={0}
      style={{
        ...style,
        rotate: "180deg",
      }}
      className="progress-sm"
    />
  );
  const positiveProgressBar = (
    <ProgressBar
      variant={positiveVariant}
      now={positiveValue}
      max={max}
      min={0}
      style={style}
      className="progress-sm"
    />
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div style={{ width: "50%" }}>{negativeProgressBar}</div>
      <div style={{ width: "50%" }}>{positiveProgressBar}</div>
    </div>
  );
}
