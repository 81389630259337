import { createColumnHelper } from '@tanstack/react-table';
import { IClusterChart } from 'api/mercadoradar/Cluster';
import classNames from 'classnames';
import { NumberFormatArrow, Table } from 'components';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function ReportsClusterSitesTable({
  data,
  loading = false,
  onCheckboxChangeCallback,
}) {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<IClusterChart>();
  const enableColumnResizing = true;
  const currency = data[0]?.currency;

  const columns = [
    columnHelper.accessor('name', {
      header: t('components.tables.site'),
      meta: {
        cellClassName: 'd-flex align-items-center',
        width: 0.2,
      },
      cell: (cell) => (
        <Form.Check
          checked={cell.row.original.visible}
          className={classNames('chart-checkbox', `checkbox-${cell.row.index}`)}
          id={cell.getValue()}
          label={cell.getValue()}
          type="checkbox"
          onChange={() => onCheckboxChangeCallback(cell.row.index)}
        />
      ),
    }),
    columnHelper.accessor('quantity_products', {
      header: t('components.tables.quantity'),
      meta: {
        headerClassName: 'justify-content-end',
        width: 0.11,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={0}
          decimalSeparator=","
          displayType="text"
          format={'0'}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('quantity_products_percent', {
      header: t('components.tables.quantityPercent'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'd-flex align-items-center justify-content-end',
        width: 0.12,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={1}
          decimalSeparator=","
          displayType="text"
          format={'0.0'}
          suffix="%"
          thousandSeparator="."
          value={cell.getValue() * 100}
        />
      ),
    }),
    columnHelper.accessor('median', {
      header: t('components.tables.median'),
      meta: {
        headerClassName: 'justify-content-end',
        width: 0.11,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('average_price', {
      header: t('components.tables.averagePrice'),
      meta: {
        headerClassName: 'justify-content-end',
        width: 0.11,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('average_price_variation', {
      header: t('components.tables.priceVariation', { currency }),
      meta: {
        headerClassName: 'justify-content-end',
        width: 0.11,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          arrow={true}
          better="positive"
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('average_price_variation_percent', {
      header: t('components.tables.variationPercent'),
      meta: {
        headerClassName: 'justify-content-end',
        width: 0.11,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          arrow={true}
          better="positive"
          decimalScale={1}
          decimalSeparator=","
          displayType="text"
          format={'0.0'}
          suffix="%"
          thousandSeparator="."
          value={cell.getValue() * 100}
        />
      ),
    }),
    columnHelper.accessor('average_total_installment_price', {
      header: t('components.tables.totalInstallmentPrice'),
      meta: {
        headerClassName: 'justify-content-end',
        width: 0.13,
        cellClassName: 'd-flex align-items-center justify-content-end',
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
  ];

  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    loading,
  };

  return <Table {...tableProps} />;
}
