import { parsePath } from "history";
import React from "react";
import { useNavigate as useNavigate_ } from "react-router-dom";

export function useNavigate() {
  const navigate = useNavigate_();

  return React.useCallback((to, { searchParams, ...options } = {}) => {
    const isSearchParamsObject = typeof searchParams === "object";
    let search;

    if (isSearchParamsObject) {
      search = new URLSearchParams(searchParams).toString();
    }
        
    to = typeof to === "string" ? parsePath(to) : to;
    return navigate({ search, ...to }, options);
  }, 
  [navigate]);
}

// Used to access navigate outside of components
export const history = {
  navigate: null,
  location: null
};