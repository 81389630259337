export default {
  translations: {
    components: {
      sideNav: {
        product: 'Produtos',
        report: 'Relatórios',
        settings: 'Configurações',
        logout: 'Sair',
      },
      sideBar: {
        buttons: {
          export: 'Exportar dados',
          saveReport: 'Salvar relatório',
          addFilter: 'Adicionar filtro',
          apply: 'Aplicar',
          reset: 'Limpar',
          addGroupBy: 'Adicionar agrupamento',
          loadMore: 'Carregar mais',
          applyTooltip: 'É necessário adicionar filtros ou agrupamentos',
        },
        inputs: {
          search: 'Buscar',
        },
        filters: 'Filtros',
        selected_one: 'selecionado',
        selected_other: 'selecionados',
        groupBy: 'Agrupamentos',
      },
      chartCard: {
        by: 'por',
      },
      chartGroupByAvailableOptions: {
        DAY: 'Dia',
        WEEK: 'Semana',
        MONTH: 'Mês',
        groupBy: 'Agrupar por',
      },
      chartAggregateByAvailableOptions: {
        MIN: 'Preço mínimo',
        AVG: 'Preço médio',
        MAX: 'Preço máximo',
      },
      tooltipCluster: {
        median: 'Mediana',
      },
      datePicker: {
        period: 'Período',
        comparedTo: 'Comparado com',
        to: 'até',
        format: 'd/m/Y',
        formatLuxon: 'dd/MM/yyyy',
      },
      emptyState: {
        nothingFound: 'Nada encontrado',
      },
      tables: {
        median: 'Mediana',
        averagePrice: 'Preço médio',
        averagePriceVariation: 'Variação do preço médio (%)',
        priceVariationPercent: 'Var. (%)',
        group: 'Grupo',
        price: 'Preço',
        priceVariation: 'Var. ({{currency}})',
        product: 'Produto',
        quantity: 'Quantidade',
        quantityPercent: 'Quantidade (%)',
        site: 'Site',
        siteSeller: 'Site / Vendedor',
        status: 'Status',
        totalInstallmentPrice: 'Preço parcelado',
        averageInstallmentPrice: 'Preço médio parcelado',
        updatedAt: 'Atualizado em',
        variationPercent: 'Var. (%)',
      },
    },
    screens: {
      auth: {
        logIn: {
          dontHaveAnAccountYet:
            'Ainda não tem uma conta? <1>Solicitar contato</1>',
          email: 'E-mail',
          emailPlaceholder: 'email@empresa.com',
          enterYourDetailsBelowToLogin:
            'Insira seus detalhes abaixo para fazer login em sua conta',
          forgotYourPassword: 'Esqueceu sua senha?',
          password: 'Senha',
          passwordPlaceholder: 'Digite sua senha',
          logIn: 'Entrar',
          welcomeBack: 'Bem-vindo de volta',
          wrongPassword:
            'E-mail ou senha incorretos. Por favor, verifique suas informações e tente novamente.',
          passwordChangedSuccessfully: 'Senha alterada com sucesso',
          tokenIsInvalid:
            'O link para alterar a senha expirou. Solicite outro em <1>Esqueceu sua senha</1>?',
        },
        signUp: {
          alreadyHaveAnAccount: 'Já tem uma conta? <1>Entrar</1>',
          createYourAccountToHaveAccessToMercadoRadar:
            'Crie sua conta para ter acesso ao Mercado Radar',
          signUp: 'Cadastrar',
          name: 'Nome',
        },
        forgotPassword: {
          enterYourEmailToGetAPasswordResetLink:
            'Digite seu e-mail para receber um link de redefinição de senha',
          forgotYourPassword: 'Esqueceu sua senha?',
          recoverPassword: 'Recuperar senha',
          rememberYourPasswordLogin: 'Lembrou da sua senha? <1>Fazer login</1>',
          checkYourEmail: 'Verifique seu e-mail',
          weHaveSentAnEmailWithInstructions:
            'Enviamos um e-mail com as instruções para você alterar sua senha no endereço informado',
        },
        resetPassword: {
          loginAgainToAccessYourAccount:
            'Faça <1>login</1> novamente para acessar sua conta.',
          changePassword: 'Alterar senha',
          createNewPasswordToAccess:
            'Crie uma nova senha para acessar o Mercado Radar',
          newPassword: 'Nova senha',
          newPasswordPlaceholder: 'Digite aqui sua nova senha',
          confirmNewPassword: 'Confirmar nova senha',
          confirmNewPasswordPlaceholder: 'Confirme sua senha',
          thePasswordsEnteredAreNotTheSame:
            'As senhas digitadas não são iguais, por favor digite novamente',
          fillInAllFields: 'Por favor, preencha todos os campos',
        },
      },
      products: {
        productsFound_one: 'produto encontrado',
        productsFound_other: 'produtos encontrados',
        priceDropped: 'Preço reduziu',
        priceIncreased: 'Preço aumentou',
        details: {
          header: {
            soldBy: 'Vendido por',
            corporateName: 'Razão social',
            address: 'Endereço',
            updatedAt: 'Atualizado em',
          },
          historyUpdateTable: {
            updateHistory: 'Histórico de atualizações',
            updatedAt: 'Atualizado em',
          },
        },
      },
      reports: {
        groupBy: 'Agrupado por',
        totalProducts: 'Total de produtos',
        averagePrice: 'Preço médio',
        createdAt: 'Criado em',
      },
      clusters: {
        header: {
          products_one: 'produto',
          products_other: 'produtos',
          from: 'de',
          to: 'para',
        },
      },
      settings: {
        notifications: 'Notificações',
        notificationDailyExport: 'Exportação diária dos dados',
        coverage: 'Cobertura',
        coverageDisclaimer1:
          'Esta página fornece informações sobre cobertura dos serviços que fazem parte do Mercado Radar.',
        coverageDisclaimer2:
          'Cobertura é o percentual de produtos que tiveram o preço capturado e atualizado com sucesso no último dia, últimos 7 dias e últimos 30 dias.',
        coverageDisclaimer3:
          'Para dúvidas ou mais informações, entre em contato com seu Gerente de Contas.',
        coverageTable: {
          name: 'Nome',
          site: 'Site',
          description: 'Descrição',
          brand: 'Marca',
          totalProducts: 'Total de produtos',
          lastDay: 'Último dia',
          lastSevenDays: 'Últimos 7 dias',
          lastThirtyDays: 'Últimos 30 dias',
        },
      },
    },
    product: {
      status: {
        ACTIVE: 'Ativo',
        OUT_OF_STOCK: 'Sem estoque',
        URL_NOT_FOUND: 'Não disponível',
      },
    },
    attributeTypes: {
      BRAND: 'Marca',
      CATEGORY: 'Categoria',
      COLOR: 'Cor',
      GENDER: 'Gênero',
      IS_KIT_SAME_PRODUCT: 'É kit do mesmo produto',
      IS_KIT: 'É kit',
      MAX_PET_WEIGHT: 'Peso máximo do animal',
      MIN_PET_WEIGHT: 'Peso mínimo do animal',
      MODEL: 'Modelo',
      PACKAGE_TYPE: 'Tipo de embalagem',
      SELLER: 'Vendedor',
      SITE: 'Site',
      SIZE: 'Tamanho',
      UNITS_PER_KIT: 'Unidades por kit',
      VOLUME: 'Volume',
    },
    attributes: {
      COLOR: {
        BLACK: 'Preto',
        BLUE: 'Azul',
        BROWN: 'Marrom',
        GRAY: 'Cinza',
        GREEN: 'Verde',
        INDIGO: 'Lilás',
        ORANGE: 'Laranja',
        PINK: 'Rosa',
        RED: 'Vermelho',
        UNDEFINED: 'Não definida',
        VIOLET: 'Violeta',
        WHITE: 'Branco',
        YELLOW: 'Amarelo',
      },
      GENDER: {
        BOY: 'Menino',
        FEMALE: 'Feminino',
        GIRL: 'Menina',
        MALE: 'Masculino',
        UNDEFINED: 'Não definido',
        UNISEX: 'Unissex',
      },
      PACKAGE_TYPE: {
        REFILL: 'Refil',
        REGULAR: 'Regular',
        SAMPLE: 'Amostra',
        SPECIAL_EDITION: 'Edição Especial',
      },
      IS_KIT: {
        TRUE: 'Sim',
        FALSE: 'Não',
      },
      IS_KIT_SAME_PRODUCT: {
        TRUE: 'Sim',
        FALSE: 'Não',
      },
    },
  },
};
