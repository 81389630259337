import { Button, Input, List } from 'antd';
import {
  useAttributeListQuery,
  useSellerListQuery,
  useSiteListQuery,
} from 'api/mercadoradar/MercadoRadarAPIClientV2';
import { Spinner } from 'components';
import ReportsFiltersCollapseListCheckbox from 'components/ReportsFilters/CollapseListItemCheckbox';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { SELLER, SITE } from 'types/AttributeType';

export default function CollapseList({ name, index, onChange }) {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const inputRef = useRef(null);

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [dataList, setDataList] = useState([]);

  const filtersCount = useSelector((state) => state.filters.count);

  let queryHook;
  let queryHookParams = { offset, search };
  if (name === SITE) {
    queryHook = useSiteListQuery;
  } else if (name === SELLER) {
    queryHook = useSellerListQuery;
  } else {
    queryHook = useAttributeListQuery;
    queryHookParams = { ...queryHookParams, attributeType: name };
  }

  const { data, isFetching } = queryHook(queryHookParams);

  const handleOnCheckboxChange = (isChecked) => {
    let newSelectedCount = filtersCount[index];
    if (isChecked) {
      newSelectedCount = newSelectedCount + 1;
    } else {
      newSelectedCount = newSelectedCount - 1;
    }
    onChange(newSelectedCount);
  };

  const handleOnClick = () => {
    setOffset(offset + 10);
  };

  const setScroll = () => {
    if (scrollRef.current) {
      if (offset > 0) {
        scrollRef.current.scrollTop = 48 * offset + 32; // altura do elemento * offset + 32px (altura do input)
      }
    }
  };

  const isSearch = searchInput.length > 0;
  const isSkeletonLoading = isFetching && dataList.length == 0 && !isSearch;
  const isSpinnerLoading = isFetching && dataList.length > 0;
  const dataSource = isSkeletonLoading
    ? Array.from({ length: 5 }, (_, idx) => ({ key: `skeleton-item-${idx}` }))
    : dataList;

  const debouncedSearch = debounce((value) => {
    setOffset(0);
    setSearch(value);
  }, 1000);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    if (data?.results) {
      let newDataList;
      if (offset == 0) {
        setDataList([]);
        newDataList = [...data.results];
      } else {
        newDataList = [...dataList, ...data.results];
      }
      setDataList(newDataList);
      setScroll();
    }
  }, [data]);

  useEffect(() => {
    if (inputRef.current && isSearch && offset == 0) {
      inputRef.current.focus();
    }
  }, [isSearch, isFetching]);

  return (
    <div>
      {!isSkeletonLoading && (
        <Input
          ref={inputRef}
          className="mb-6"
          placeholder={t('components.sideBar.inputs.search')}
          suffix={isFetching && isSearch ? <Spinner size="small" /> : null}
          value={searchInput}
          onChange={handleSearchChange}
        />
      )}
      <div
        ref={scrollRef}
        style={{ maxHeight: '240px', overflowY: 'auto', overflowX: 'hidden' }}
      >
        <List
          dataSource={dataSource}
          renderItem={(item, index) => (
            <List.Item key={index}>
              {isSkeletonLoading ? (
                <Skeleton height="22px" width="300px" />
              ) : (
                <ReportsFiltersCollapseListCheckbox
                  name={name}
                  text={item?.text}
                  value={item?.id}
                  onChange={handleOnCheckboxChange}
                />
              )}
            </List.Item>
          )}
        />
        {data?.next && (
          <List.Item
            key={`load-more-${name}`}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button className="mb-8" type="link" onClick={handleOnClick}>
              {isSpinnerLoading && <Spinner size="small" />}
              {!isFetching && t('components.sideBar.buttons.loadMore')}
            </Button>
          </List.Item>
        )}
      </div>
    </div>
  );
}
