import {
  AttributeAPI,
  CrawlerSearchesAPI,
  SiteAPI,
  UserAPI,
} from 'api/mercadoradar';
import { Card } from 'components';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CoverageTable from 'screens/settings/components/CoverageTable';
import CrawlerSearchesTable from 'screens/settings/components/CrawlerSearchesTable';
import { setHeaderLoading } from 'store/header';

export default function SettingsPage() {
  const userAPI = new UserAPI();
  const siteAPI = new SiteAPI();
  const attributeApi = new AttributeAPI();
  const searchesAPI = new CrawlerSearchesAPI();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(setHeaderLoading(false));

  // #region states
  const [user, setUser] = useState({ notification_daily_export: true });
  const [site, setSite] = useState([]);
  const [overallCoverage, setOverallCoverage] = useState(0);
  const [attribute, setAttribute] = useState([]);
  const [searches, setSearches] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [siteLoading, setSiteLoading] = useState(false);
  const [attributeLoading, setAttributeLoading] = useState(false);
  const [searchesLoading, setSearchesLoading] = useState(false);
  // #endregion states

  // #region functions
  const updateUser = (data) => {
    setUser({ ...user, ...data });
    userAPI.me().update(data);
  };
  // #endregion functions

  useEffect(() => {
    window.scrollTo(0, 0);

    async function getUser() {
      setUserLoading(true);
      const user = await userAPI.me().get();
      setUser(user);
      setUserLoading(false);
    }

    async function getSiteCoverage() {
      setSiteLoading(true);
      const siteCoverage = await siteAPI.coverage();
      setSite(siteCoverage?.results);
      setOverallCoverage(siteCoverage?.coverage);
      setSiteLoading(false);
    }

    async function getAttributeCoverage() {
      setAttributeLoading(true);
      const attributeCoverage = await attributeApi.coverage_by_brand();

      setAttribute(attributeCoverage);
      setAttributeLoading(false);
    }

    async function getSearches() {
      setSearchesLoading(true);
      const searches = await searchesAPI.list({ limit: 10000 });
      setSearches(searches?.results);
      setSearchesLoading(false);
    }

    getUser();
    getSiteCoverage();
    getAttributeCoverage();
    getSearches();
  }, []);

  return (
    <>
      <Col>
        <Row className="mb-12">
          <Col>
            <Card header={t('screens.settings.notifications')}>
              <Row className="mb-12">
                <Col>
                  <Form.Check
                    type="switch"
                    label={t('screens.settings.notificationDailyExport')}
                    checked={user?.notification_daily_export}
                    htmlFor="notification-daily-export"
                    id="nofitication-daily-export"
                    onChange={() =>
                      updateUser({
                        notification_daily_export:
                          !user?.notification_daily_export,
                      })
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className="mb-12">
          <Col>
            <Card header={t('screens.settings.coverage')}>
              <Row className="mb-24">
                <Col>
                  <span className="d-block">
                    {t('screens.settings.coverageDisclaimer1')}
                  </span>
                  <span className="d-block">
                    {t('screens.settings.coverageDisclaimer2')}
                  </span>
                  <span className="d-block">
                    {t('screens.settings.coverageDisclaimer3')}
                  </span>
                </Col>
              </Row>
              <Row className="mb-20">
                <Col>
                  <Row>
                    <span>Sites</span>
                  </Row>
                  <Row className="mt-8">
                    <CoverageTable
                      data={site}
                      loading={siteLoading}
                      sorting={[{ id: 'total_products', desc: true }]}
                    />
                  </Row>
                </Col>
              </Row>
              <Row className="mb-20">
                <Col>
                  <Row>
                    <span>Marcas</span>
                  </Row>
                  <Row className="mt-8">
                    <CoverageTable
                      data={attribute}
                      loading={attributeLoading}
                      sorting={[{ id: 'total_products', desc: true }]}
                    />
                  </Row>
                </Col>
              </Row>
              <Row className="mb-20">
                <Col>
                  <Row>
                    <span>Buscas</span>
                  </Row>
                  <Row className="mt-8">
                    <CrawlerSearchesTable
                      data={searches}
                      loading={searchesLoading}
                      sorting={[{ id: 'total_products', desc: true }]}
                    />
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
}
