import MercadoRadarAPIClient from 'api/mercadoradar/MercadoRadarAPIClient';

const resource = 'v2/export/';

interface IHistoryExport {
  redirect: boolean;
}

export default class ExportAPI extends MercadoRadarAPIClient {
  constructor() {
    super(resource);
  }

  async getHistoryByDate(date: string): Promise<IHistoryExport> {
    const params = { export_format: 'xlsx' };
    const response = await this.client.get(
      `${this.resource}product-history/${date}/`,
      { params },
    );
    const isBackendRedirect = !response.request.responseURL.includes(
      response.config.baseURL,
    );

    return { redirect: isBackendRedirect };
  }
}
