import { App } from 'App';
import AuthCreatePage from 'pages/auth/create';
import AuthForgotPasswordPage from 'pages/auth/forgotPassword';
import AuthLoginPage from 'pages/auth/login';
import AuthResetPasswordPage from 'pages/auth/resetPassword';
import ErrorPage from 'pages/error';
import ExportHistoryByDatePage from 'pages/export/historyByDate';
import ProductPage from 'pages/product';
import ProductDetailPage from 'pages/product/detail';
import ReportPage from 'pages/report';
import ReportDetailPage from 'pages/report/detail';
import ReportEditPage from 'pages/report/edit';
import SettingsPage from 'pages/settings';
import { createBrowserRouter } from 'react-router-dom';
import PrivateLayout from 'screens/layout/PrivateLayout';

const errorPage = <ErrorPage />;

const authLoginPage = <AuthLoginPage />;
const authCreatePage = <AuthCreatePage />;
const authForgotPasswordPage = <AuthForgotPasswordPage />;
const authResetPasswordPage = <AuthResetPasswordPage />;

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        children: [
          {
            path: '*',
            element: authLoginPage,
          },
          {
            path: '/auth/login',
            element: authLoginPage,
          },
          {
            path: '/auth/create',
            element: authCreatePage,
          },
          {
            path: '/auth/forgotPassword',
            element: authForgotPasswordPage,
          },
          {
            path: '/auth/resetPassword',
            element: authResetPasswordPage,
          },
        ],
      },
      {
        element: <PrivateLayout />,
        children: [
          {
            path: '/product',
            element: <ProductPage />,
          },
          {
            path: '/product/:productId',
            element: <ProductDetailPage />,
          },
          {
            path: '/report',
            element: <ReportPage />,
          },
          {
            path: '/report/edit',
            element: <ReportEditPage />,
          },
          {
            path: '/report/detail',
            element: <ReportDetailPage />,
          },
          {
            path: '/export/product-history/:date',
            element: <ExportHistoryByDatePage />,
          },
          {
            path: '/settings',
            element: <SettingsPage />,
          },
        ],
      },
    ],
  },
]);

export default router;
