import { Chart } from "components";
import { Chart as HighchartsChart } from "highcharts";
import { useCallback, useState } from "react";
import { ProductDetailHistoryChartTooltip } from "screens/products/components";

export default function ProductDetailHistoryChart({ data, aggregateBy, loading = false }) {
  const activeColor = "#1f77b4";
  const outOfStockColor = "var(--bs-danger)";
  const urlNotFoundColor = "var(--bs-gray-800)";

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const callback = useCallback((chart: HighchartsChart) => {
    setChart(chart);
  }, []);

  const zones = data.map((point, index) => {
    let color = activeColor;

    if (point.status == "OUT_OF_STOCK") {
      color = outOfStockColor;
    } else if (point.status == "URL_NOT_FOUND") {
      color = urlNotFoundColor;
    }

    if (index < data.length) return { value: data[index + 1]?.x, color: color };
  });

  const series = [
    {
      name: "Preço",
      data: [...data],
      zones,
      zoneAxis: "x",
    },
  ];

  return (
    <Chart
      series={series}
      timeseries={true}
      showLegend={false}
      callback={callback}
      loading={loading}
    >
      <ProductDetailHistoryChartTooltip chart={chart} aggregateBy={aggregateBy}/>
    </Chart>
  );
}
