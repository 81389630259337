import {
  faCaretDown,
  faCaretUp,
  faCirclePause,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function ProductPriceVariationAndStatusTag({
  variation,
  status,
}) {
  const { t } = useTranslation();
  const greenStyle = { color: 'var(--bs-success)' };
  const redStyle = { color: 'var(--bs-danger)' };
  const mutedStyle = { color: 'var(--bs-gray-700)' };
  const emptyElement = <div style={{ minHeight: '18px' }}></div>;
  const variationPercent = Math.round(variation * 100);
  const renderVariation =
    variationPercent != 0 && variation != null && status == 'ACTIVE';
  const renderStatus = status !== 'ACTIVE';
  let style;
  let icon;
  let textKey;

  if (status == 'ACTIVE') {
    if (variationPercent > 0) {
      style = greenStyle;
      icon = faCaretUp;
      textKey = 'screens.products.priceIncreased';
    } else if (variationPercent < 0) {
      style = redStyle;
      icon = faCaretDown;
      textKey = 'screens.products.priceDropped';
    } else {
      return emptyElement;
    }
  } else if (status == 'OUT_OF_STOCK') {
    style = mutedStyle;
    icon = faCirclePause;
  } else if (status == 'URL_NOT_FOUND') {
    style = mutedStyle;
    icon = faCircleXmark;
  } else {
    return emptyElement;
  }

  return (
    <span style={style}>
      <b>
        <FontAwesomeIcon className="me-1" icon={icon} />
        {textKey && <span>{t(textKey)}</span>}&nbsp;
        {renderStatus && <span>{t(`product.status.${status}`)}</span>}&nbsp;
        {renderVariation && <span>{`${Math.abs(variationPercent)}%`}</span>}
      </b>
    </span>
  );
}
