import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ProductStatusTag({ status }) {
  const { t } = useTranslation();

  let bgColor = "success";

  if (status == "OUT_OF_STOCK") bgColor = "danger";

  if (status == "URL_NOT_FOUND") bgColor = "primary";

  return (
    <Badge pill bg={`${bgColor}-soft`}>
      <span className="fs-12" style={{ lineHeight: "20px" }}>
        {t(`product.status.${status}`)}
      </span>
    </Badge>
  );
}
