import Card from 'components/cards/Card';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const CardTable = ({ header, headerRight, loading, children }) => {
  const skeletonHeader = (
    <Row>
      {Array(5)
        .fill()
        .map((_, index) => (
          <Col key={index}>
            <Skeleton height="25" width="100%" />
          </Col>
        ))}
    </Row>
  );

  const skeletonColumns = Array(5)
    .fill()
    .map((_, index) => (
      <Col key={index}>
        <Skeleton height="20" width="80%" />
      </Col>
    ));

  const skeletonTable = (
    <>
      {skeletonHeader}
      {Array(15)
        .fill()
        .map((_, index) => (
          <Row key={index} className="mt-8">
            {skeletonColumns}
          </Row>
        ))}
    </>
  );

  return (
    <Card header={header} headerRight={headerRight} loading={loading}>
      {loading ? skeletonTable : children}
    </Card>
  );
};

CardTable.propTypes = {
  header: PropTypes.string,
  headerRight: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

CardTable.defaultProps = {
  loading: true,
};

export default CardTable;
