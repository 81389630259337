import { createColumnHelper } from '@tanstack/react-table';
import { NumberFormatArrow, Table } from 'components';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setDetail } from 'store/reportsSlice';
import { TotalProductsGroups } from 'types/Report';

const ReportsTotalProductsTable = ({ groupName, data, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columnGroupName = t(`attributeTypes.${groupName}`);
  const enableColumnResizing = true;
  const enableSorting = true;
  const columnHelper = createColumnHelper<TotalProductsGroups>();

  const handleOnClick = (groupBy, params) => {
    dispatch(setDetail({ groupBy, params }));
  };

  const columns = [
    columnHelper.accessor('value', {
      header: columnGroupName,
      meta: {
        width: 0.4,
      },
      cell: ({ cell, row }) => {
        const groupBy = row.original.endpoint_parameters.path_param;
        const params = row.original.endpoint_parameters.temp_query_params;
        return (
          <Link
            to="/report/detail"
            onClick={() => handleOnClick(groupBy, params)}
          >
            {cell.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor('quantity', {
      header: t('components.tables.quantity'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={0}
          decimalSeparator=","
          displayType="text"
          format={'0'}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('quantity_percent', {
      header: t('components.tables.quantityPercent'),
      meta: {
        width: 0.4,
      },
      cell: (cell) => (
        <Row>
          <Col className="text-end" md={4} xl={3} xxl={2}>
            <NumberFormatArrow
              fixedDecimalScale
              decimalScale={1}
              decimalSeparator=","
              displayType="text"
              format={'0.0'}
              suffix="%"
              thousandSeparator="."
              value={cell.getValue() * 100}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <div style={{ width: '100%' }}>
              <ProgressBar
                className="progress-sm"
                max={1}
                now={cell.getValue()}
                style={{ margin: 'auto' }}
              />
            </div>
          </Col>
        </Row>
      ),
    }),
    columnHelper.accessor('quantity_previous_period_variation', {
      header: t('components.tables.variationPercent'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          arrow={true}
          better="positive"
          decimalScale={1}
          decimalSeparator=","
          displayType="text"
          format={'0.0'}
          ifZero={'-'}
          suffix="%"
          thousandSeparator="."
          value={cell.getValue() * 100}
        />
      ),
    }),
  ];

  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    enableSorting,
  };

  return <Table {...tableProps} loading={loading} />;
};

ReportsTotalProductsTable.propTypes = {
  groupName: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
};

ReportsTotalProductsTable.defaultProps = {
  loading: true,
};

export default ReportsTotalProductsTable;
