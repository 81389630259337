import { createColumnHelper } from '@tanstack/react-table';
import {
  NumberFormatArrow,
  ProgressBarPositiveNegative,
  Table,
} from 'components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setDetail } from 'store/reportsSlice';
import { Cluster } from 'types/Report';

const ReportsClustersTable = ({ data, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // #region Functions
  function getMinAveragePriceVariation() {
    const minValue = _.minBy(
      data,
      (element) => element.average_price_variation,
    );
    const min = minValue?.average_price_variation;
    return min;
  }

  function getMaxAveragePriceVariation() {
    const maxValue = _.maxBy(
      data,
      (element) => element.average_price_variation,
    );
    const max = maxValue?.average_price_variation;
    return max;
  }
  // #endregion Functions

  // #region Elements
  const priceVariationProgressBar = (cell) => (
    <ProgressBarPositiveNegative
      max={getMaxAveragePriceVariation()}
      min={getMinAveragePriceVariation()}
      now={cell.getValue()}
      style={{ margin: 'auto' }}
    />
  );

  const handleOnClick = (groupBy, params) => {
    dispatch(setDetail({ groupBy, params }));
  };
  // #endregion Elements

  const columnHelper = createColumnHelper<Cluster>();
  const enableColumnResizing = true;
  const enableSorting = true;

  const columns = [
    columnHelper.accessor('name', {
      header: t('components.tables.group'),
      meta: {
        width: 0.25,
      },
      cell: ({ cell, row }) => {
        const groupBy = row.original.endpoint_parameters.path_param;
        const queryParams = row.original.endpoint_parameters.temp_query_params;
        return (
          <Link
            to="/report/detail"
            onClick={() => handleOnClick(groupBy, queryParams)}
          >
            {cell.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor('quantity', {
      header: t('components.tables.quantity'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={0}
          decimalSeparator=","
          displayType="text"
          format={'0'}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('quantity_percent', {
      header: t('components.tables.quantityPercent'),
      meta: {
        width: 0.275,
      },
      cell: (cell) => (
        <Row>
          <Col className="text-end" md={5} xl={4} xxl={3}>
            <NumberFormatArrow
              fixedDecimalScale
              decimalScale={1}
              decimalSeparator=","
              displayType="text"
              format={'0.0'}
              suffix="%"
              thousandSeparator="."
              value={cell.getValue() * 100}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <div style={{ width: '100%' }}>
              <ProgressBar
                className="progress-sm"
                max={1}
                now={cell.getValue()}
                style={{ margin: 'auto' }}
              />
            </div>
          </Col>
        </Row>
      ),
    }),
    columnHelper.accessor('average_price', {
      header: t('components.tables.averagePrice'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.1,
      },
      cell: (cell) => (
        <NumberFormatArrow
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          format={'0.00'}
          prefix={`${cell.row.original.currency} `}
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('average_price_variation', {
      header: t('components.tables.averagePriceVariation'),
      meta: {
        width: 0.275,
      },
      cell: (cell) => (
        <Row>
          <Col className="text-end" md={4} xl={3} xxl={3}>
            <NumberFormatArrow
              fixedDecimalScale
              decimalScale={1}
              decimalSeparator=","
              displayType="text"
              format={'0.0'}
              suffix="%"
              thousandSeparator="."
              value={cell.getValue() * 100}
            />
          </Col>
          <Col>{priceVariationProgressBar(cell)}</Col>
        </Row>
      ),
    }),
  ];

  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    enableSorting,
    loading,
  };

  return <Table {...tableProps} />;
};

ReportsClustersTable.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

ReportsClustersTable.defaulPropTypes = {
  loading: true,
};

export default ReportsClustersTable;
