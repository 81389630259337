import { ProductAPI } from 'api/mercadoradar';
import {
  IProduct,
  IProductHistory,
  IProductHistoryChartResults,
} from 'api/mercadoradar/Product';
import { Card, Select } from 'components';
import { options as aggregateByAvailableOptions } from 'enums/chartAggregateByAvailableOptions';
import { options as groupByAvailableOptions } from 'enums/chartGroupByAvailableOptions';
import { useEffect, useState, lazy, Suspense } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  pushHeaderSubTitle,
  setHeaderLoading,
  setHeaderTitle,
} from 'store/header';

const ReportDatePickerRange = lazy(
  () => import('screens/components/ReportDatePickerRange'),
);
const EmptyState = lazy(() => import('screens/layout/components/EmptyState'));
const ProductDetailHeader = lazy(
  () => import('screens/products/components/details/ProductDetailHeader'),
);
const ProductDetailHistoryChart = lazy(
  () => import('screens/products/components/details/ProductDetailHistoryChart'),
);
const ProductDetailHistoryTable = lazy(
  () => import('screens/products/components/details/ProductDetailHistoryTable'),
);

export default function ProductDetailPage() {
  const productApi = new ProductAPI();
  const { productId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultGroupBy = 'DAY';
  const defaultAggregateBy = 'AVG';

  // #region States
  const [product, setProduct] = useState<IProduct>();
  const [productLoading, setProductLoading] = useState(true);

  const dateGte = useSelector((state) => state.reports.dateGte);
  const dateLte = useSelector((state) => state.reports.dateLte);

  const [groupBy, setGroupBy] = useState(defaultGroupBy);
  const [aggregateBy, setAggregateBy] = useState(defaultAggregateBy);

  const [productHistoryData, setProductHistoryData] = useState<
    IProductHistory[]
  >([]);
  const hasProductHistoryData = productHistoryData.length > 0;
  const [productHistoryLoading, setProductHistoryLoading] = useState(true);

  const [productHistoryChartData, setProductHistoryChartData] =
    useState<IProductHistoryChartResults>([]);
  const [productHistoryChartLoading, setProductHistoryChartLoading] =
    useState(true);
  const [chartTitle, setChartTitle] = useState(() =>
    getChartTitle(defaultAggregateBy, defaultGroupBy),
  );

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const hasDates = dateGte !== undefined && dateLte !== undefined;

  const params = {
    date__gte: dateGte,
    date__lte: dateLte,
    group_by: groupBy,
    aggregate_by: aggregateBy,
  };

  // #endregion states

  // #region functions

  async function getProduct() {
    dispatch(setHeaderLoading(true));
    setProductLoading(true);

    const data: IProduct = await productApi.retrieve(productId);
    setProduct(data);
    dispatch(setHeaderTitle(data?.name));
    dispatch(pushHeaderSubTitle({ text: data?.name, path: location.pathname }));

    setProductLoading(false);
    dispatch(setHeaderLoading(false));
  }

  async function getHistory() {
    setProductHistoryLoading(true);

    const data = await productApi.getHistory(productId, params);

    setProductHistoryData(data.results);
    setProductHistoryLoading(false);
  }

  async function getHistoryChart() {
    setProductHistoryChartLoading(true);

    const data = await productApi.getHistoryChart(productId, params);
    setProductHistoryChartData(data);
    setProductHistoryChartLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isFirstLoad) {
      getProduct();
      setIsFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    if (hasDates) getHistory();
  }, [dateGte, dateLte]);

  useEffect(() => {
    if (hasDates) getHistoryChart();
  }, [dateGte, dateLte, groupBy, aggregateBy]);

  // TODO: Componentizar
  const groupByOptions = groupByAvailableOptions();

  const aggregateByOptions = aggregateByAvailableOptions();

  function handleGroupBy(option) {
    const selectedAggregateBy = getSelectedAggregateBy();
    setChartTitle(getChartTitle(selectedAggregateBy.value, option.value));
    setGroupBy(option.value);
  }

  function handleAggregateBy(option) {
    const selectedGroupBy = getSelectedGroupBy();
    setChartTitle(getChartTitle(option.value, selectedGroupBy.value));
    setAggregateBy(option.value);
  }

  function getChartTitle(aggregateBy, groupBy) {
    const aggregateByKey = `components.chartAggregateByAvailableOptions.${aggregateBy}`;
    const aggregateByText = t(aggregateByKey);

    const byText = t('components.chartCard.by');

    const groupByText = t(
      `components.chartGroupByAvailableOptions.${groupBy}`,
    ).toLowerCase();

    return `${aggregateByText} ${byText} ${groupByText}`;
  }

  function getSelectedGroupBy() {
    return groupByOptions.filter((option) => option.value === groupBy)[0];
  }

  function getSelectedAggregateBy() {
    return aggregateByOptions.filter(
      (option) => option.value === aggregateBy,
    )[0];
  }

  // #endregion functions

  // #region elements

  const selectedGroupBy = getSelectedGroupBy();
  const selectedAggregateBy = getSelectedAggregateBy();

  const groupByElement = (
    <Row className="justify-content-end">
      <Col className="d-flex flex-column justify-content-center" md="auto">
        <span className="fs-13 fw-normal text-gray-800">
          {t('components.chartGroupByAvailableOptions.groupBy')}:
        </span>
      </Col>
      <Col className="d-flex flex-column justify-content-center" md="auto">
        <div style={{ width: '100px' }}>
          <Select
            defaultValue={selectedGroupBy}
            options={groupByOptions}
            onChange={(option) => handleGroupBy(option)}
          />
        </div>
      </Col>
      <Col className="d-flex flex-column justify-content-center" md="auto">
        <div style={{ width: '150px' }}>
          <Select
            defaultValue={selectedAggregateBy}
            options={aggregateByOptions}
            onChange={(option) => handleAggregateBy(option)}
          />
        </div>
      </Col>
    </Row>
  );

  // #endregion elements

  return (
    <>
      <Row className="mb-12">
        <Col>
          <Suspense>
            <ProductDetailHeader
              attributes={product?.attributes}
              currency={product?.currency}
              loading={productLoading}
              path_from_root_category={product?.path_from_root_category}
              picture={product?.picture}
              price={product?.current_price}
              price_updated_at={product?.price_updated_at}
              seller={product?.seller}
              site_name={product?.site.name}
              status={product?.status}
              title={product?.name}
              url={product?.url}
              variation_percent={product?.variation_percent}
            />
          </Suspense>
        </Col>
      </Row>
      <Row className="mb-12">
        <Col>
          <Suspense>
            <ReportDatePickerRange
              comparison={false}
              loading={productHistoryChartLoading}
            />
          </Suspense>
        </Col>
      </Row>
      <Row className="mb-12">
        <Col>
          <Card
            header={chartTitle}
            headerRight={groupByElement}
            loading={productHistoryChartLoading}
          >
            <Suspense>
              <ProductDetailHistoryChart
                aggregateBy={aggregateBy}
                data={productHistoryChartData}
                loading={productHistoryChartLoading}
              />
            </Suspense>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            header={t(
              'screens.products.details.historyUpdateTable.updateHistory',
            )}
            loading={productHistoryChartLoading}
          >
            <Suspense>
              <div>
                {hasProductHistoryData || productHistoryLoading ? (
                  <ProductDetailHistoryTable
                    data={productHistoryData}
                    loading={productHistoryLoading}
                  />
                ) : (
                  <EmptyState />
                )}
              </div>
            </Suspense>
          </Card>
        </Col>
      </Row>
    </>
  );
}
