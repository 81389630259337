import { createColumnHelper } from '@tanstack/react-table';
import { IProductHistory } from 'api/mercadoradar/Product';
import { NumberFormatArrow, Table } from 'components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import ProductStatusTag from 'screens/products/components/ProductStatusTag';

export default function ProductDetailHistoryTable({ data, loading }) {
  const { t } = useTranslation();

  const enableColumnResizing = true;
  const columnHelper = createColumnHelper<IProductHistory>();

  const currency = data[0]?.currency;

  function formatDate(text, format) {
    return DateTime.fromISO(text).toFormat(format);
  }

  const columns = [
    columnHelper.accessor('created_at', {
      header: t('screens.products.details.historyUpdateTable.updatedAt'),
      meta: {
        width: 0.167,
      },
      cell: (cell) => formatDate(cell.getValue(), 'dd/MM/yyyy HH:mm'),
    }),
    columnHelper.accessor('status__display', {
      header: t('components.tables.status'),
      meta: {
        width: 0.167,
      },
      cell: (cell) => (
        <div className="d-grid">
          <ProductStatusTag status={cell.row.original.status} />
        </div>
      ),
    }),
    columnHelper.accessor('sales_price', {
      header: t('components.tables.price'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.167,
      },
      cell: (cell) => (
        <NumberFormatArrow
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          format={'0.00'}
          prefix={`${cell.row.original.currency} `}
          decimalScale={2}
          fixedDecimalScale
          value={cell.getValue()}
        />
      ),
    }),
    columnHelper.accessor('variation_price', {
      header: t('components.tables.priceVariation', { currency }),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.167,
      },
      cell: (cell) => (
        <NumberFormatArrow
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          format={'0.00'}
          prefix={`${cell.row.original.currency} `}
          decimalScale={2}
          fixedDecimalScale
          better="positive"
          arrow={true}
          value={cell.getValue()}
          ifZero={'-'}
        />
      ),
    }),
    columnHelper.accessor('variation_percent', {
      header: t('components.tables.priceVariationPercent'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.166,
      },
      cell: (cell) => (
        <NumberFormatArrow
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          format={'0.0'}
          suffix="%"
          decimalScale={1}
          fixedDecimalScale
          better="positive"
          arrow={true}
          value={cell.getValue() * 100}
          ifZero={'-'}
        />
      ),
    }),
    columnHelper.accessor('installment_price', {
      header: t('components.tables.totalInstallmentPrice'),
      meta: {
        headerClassName: 'justify-content-end',
        cellClassName: 'text-end',
        width: 0.166,
      },
      cell: (cell) => (
        <NumberFormatArrow
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          format={'0.0'}
          prefix={`${cell.row.original.currency} `}
          decimalScale={1}
          fixedDecimalScale
          value={cell.getValue()}
          ifZero={'-'}
        />
      ),
    }),
  ];
  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    loading,
  };

  return (
    <Table {...tableProps} enableSorting={true} enableColumnResizing={true} />
  );
}
