import { ChartTooltip } from "components";
import { Chart as HighchartsChart } from "highcharts";
import { DateTime } from "luxon";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

declare interface IProductDetailHistoryChartTooltip {
  chart: HighchartsChart;
  aggregateBy: string;
}

export default function ProductDetailHistoryChartTooltip({
  chart,
  aggregateBy
}: IProductDetailHistoryChartTooltip) {
  const { t } = useTranslation();

  return (
    <ChartTooltip chart={chart}>
      {(formatterContext) => {
        const { x, y, point } = formatterContext;

        const status = point.status;
        let statusBorderColor = "--bs-success";

        if (status == "OUT_OF_STOCK") statusBorderColor = "--bs-danger";
        if (status == "URL_NOT_FOUND") statusBorderColor = "--bs-gray-800";

        const aggregates = {
          MIN: point.lowest_price,
          AVG: point.average_price,
          MAX: point.highest_price
        };
        
        const aggregatorValues = Object.entries(aggregates).map(([aggregate, value]) => {
          if (aggregate !== aggregateBy) {
            return (
              <Row key={aggregate}>
                <Col>
                  <NumericFormat
                    displayType="text"
                    prefix={`${t("components.chartAggregateByAvailableOptions." + aggregate)}: ${point?.currency} `}
                    thousandSeparator="."
                    decimalSeparator=","
                    format="0.00"
                    decimalScale={2}
                    fixedDecimalScale
                    value={value}
                  />
                </Col>
              </Row>
            );
          }
        });

        return (
          <div className="chart-tooltip">
            <div
              className="ps-6"
              style={{ borderLeft: `solid 5px var(${statusBorderColor})` }}
            >
              <span className="d-block fs-17 text-gray-800">
                {point.status__display}
              </span>
              <span className="d-block fs-17 text-gray-800 fw-700">
                {DateTime.fromJSDate(x).toFormat("dd/MM/yyyy")}
              </span>
              <span className="mt-4 d-block fs-22 text-gray-800 fw-800">
                <NumericFormat
                  displayType="text"
                  prefix={`${point?.currency} `}
                  thousandSeparator="."
                  decimalSeparator=","
                  format={"0.00"}
                  decimalScale={2}
                  fixedDecimalScale
                  value={y}
                />
              </span>
              <span className="mt-4 d-block fs-15 text-gray-800">
                <Container fluid="xxl">
                  {aggregatorValues}
                </Container>
              </span>
            </div>
          </div>
        );
      }}
    </ChartTooltip>
  );
}
