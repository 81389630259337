import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

declare interface IAttributes {
  attributes: Array<unknown>;
  loading: boolean;
  path_from_root_category: string;
  site_name: string;
}

export default function ProductDetailAttributesTable({
  attributes,
  loading,
  path_from_root_category,
  site_name,
}: IAttributes) {
  const { t } = useTranslation();

  const skeleton = (
    <>
      <Skeleton width={'100%'} height={20} className="mb-15" />
      <Skeleton width={'100%'} height={20} className="mb-15" />
      <Skeleton width={'100%'} height={20} className="mb-15" />
      <Skeleton width={'100%'} height={20} className="mb-15" />
      <Skeleton width={'100%'} height={20} />
    </>
  );

  const table = (
    <div className="product-detail-table">
      <Table striped bordered className="fs-12">
        <tbody>
          {site_name && (
            <tr>
              <td>
                <span className="text text-gray-800">
                  {t('attributeTypes.SITE')}
                </span>
              </td>
              <td>
                <span className="text text-gray-800">{site_name}</span>
              </td>
            </tr>
          )}
          {path_from_root_category && (
            <tr>
              <td>
                <span className="text text-gray-800">
                  {t('attributeTypes.CATEGORY')}
                </span>
              </td>
              <td>
                <span className="text text-gray-800">
                  {path_from_root_category}
                </span>
              </td>
            </tr>
          )}
          {attributes.map((attribute, attributeIndex) => {
            return (
              <tr key={attributeIndex}>
                <td>
                  <span className="text text-gray-800">
                    {t(`attributeTypes.${attribute.name__display}`)}
                  </span>
                </td>
                <td>
                  <span className="text text-gray-800">{attribute.value}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );

  return loading ? skeleton : table;
}
