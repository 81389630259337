export default {
  translations: {
    components: {
      sideNav: {
        product: 'Products',
        report: 'Reports',
        settings: 'Settings',
        logout: 'Logout',
      },
      sideBar: {
        buttons: {
          export: 'Export data',
          saveReport: 'Save report',
          apply: 'Apply',
          addFilter: 'Add filter',
          selected_one: 'selected',
          selected_other: 'selected',
          addGroupBy: 'Add group by',
          loadMore: 'Load more',
          applyTooltip: 'First you need to add filters or group by',
        },
        inputs: {
          search: 'Buscar',
        },
        filters: 'Filters',
        groupBy: 'Group by',
      },
      chartCard: {
        by: 'by',
      },
      chartGroupByAvailableOptions: {
        DAY: 'Day',
        WEEK: 'Week',
        MONTH: 'Month',
        groupBy: 'Group by',
      },
      chartAggregateByAvailableOptions: {
        MIN: 'Minimum price',
        AVG: 'Average price',
        MAX: 'Maximum price',
      },
      tooltipCluster: {
        median: 'Median',
      },
      datePicker: {
        period: 'Period',
        comparedTo: 'Compared to',
        to: 'to',
        format: 'm/d/Y',
        formatLuxon: 'MM/dd/yyyy',
      },
      emptyState: {
        nothingFound: 'Nothing found',
      },
      tables: {
        median: 'Median',
        averagePrice: 'Average price',
        averagePriceVariation: 'Average price variation (%)',
        priceVariationPercent: 'Var. (%)',
        group: 'Group',
        price: 'Price',
        priceVariation: 'Var. ({{currency}})',
        product: 'Product',
        quantity: 'Quantity',
        quantityPercent: 'Quantity (%)',
        site: 'Site',
        siteSeller: 'Site / Seller',
        status: 'Status',
        totalInstallmentPrice: 'Installment price',
        averageInstallmentPrice: 'Average installment price',
        updatedAt: 'Updated at',
        variationPercent: 'Var. (%)',
      },
    },
    screens: {
      auth: {
        logIn: {
          dontHaveAnAccountYet:
            "Don't have an account yet? <1>Request a contact</1>",
          email: 'E-mail',
          emailPlaceholder: 'email@company.com',
          enterYourDetailsBelowToLogin:
            'Enter your details below to log in to your account',
          forgotYourPassword: 'Forgot your password?',
          password: 'Password',
          passwordPlaceholder: 'Enter your password',
          logIn: 'Log in',
          welcomeBack: 'Welcome back',
          wrongPassword:
            'Incorrect email or password. Please check your information and try again.',
          passwordChangedSuccessfully: 'Password changed successfully',
          tokenIsInvalid:
            'The link to change the password has expired. Request another one at <1>Forgot your password?</1>',
        },
        signUp: {
          alreadyHaveAnAccount: 'Already have an account? <1>Log in</1>',
          createYourAccountToHaveAccessToMercadoRadar:
            'Create your account to have access to Mercado Radar',
          signUp: 'Sign up',
          name: 'Name',
        },
        forgotPassword: {
          enterYourEmailToGetAPasswordResetLink:
            'Enter your email to get a password reset link',
          forgotYourPassword: 'Forgot your password?',
          recoverPassword: 'Recover password',
          rememberYourPasswordLogin: 'Remembered your password? <1>Log in</1>',
          checkYourEmail: 'Check your e-mail',
          weHaveSentAnEmailWithInstructions:
            'We have sent an e-mail with instructions to change your password at the provided e-mail address',
        },
        resetPassword: {
          loginAgainToAccessYourAccount:
            '<1>Log in</1> again to access your account.',
          changePassword: 'Change password',
          createNewPasswordToAccess:
            'Create a new password to access the Mercado Radar',
          newPassword: 'New password',
          newPasswordPlaceholder: 'Enter here your new password',
          confirmNewPassword: 'Confirm new password',
          confirmNewPasswordPlaceholder: 'Confirm password',
          thePasswordsEnteredAreNotTheSame:
            'The passwords you have typed in do not match, please type them in again',
          fillInAllFields: 'Please fill in all fields',
        },
      },
      products: {
        productsFound_one: 'product found',
        productsFound_other: 'products found',
        priceDropped: 'Price dropped',
        priceIncreased: 'Price increased',
        details: {
          header: {
            soldBy: 'Sold by',
            corporateName: 'Corporate name',
            address: 'Address',
            updatedAt: 'Updated at',
          },
          historyUpdateTable: {
            updateHistory: 'Update history',
            updatedAt: 'Updated at',
          },
        },
      },
      reports: {
        groupBy: 'Group By',
        totalProducts: 'Total products',
        averagePrice: 'Average price',
        createdAt: 'Created at',
      },
      clusters: {
        header: {
          products_one: 'product',
          products_other: 'products',
          from: 'from',
          to: 'to',
        },
      },
      settings: {
        notifications: 'Notifications',
        notificationDailyExport: 'Daily data export',
        coverage: 'Coverage',
        coverageDisclaimer1:
          'This page provides information about the coverage of services that are part of Mercado Radar.',
        coverageDisclaimer2:
          'Coverage is the percentage of products that had their price successfully captured and updated in the last day, last 7 days and last 30 days.',
        coverageDisclaimer3:
          'For questions or more information, please contact your Account Manager.',
        coverageTable: {
          name: 'Name',
          site: 'Site',
          brand: 'Brand',
          description: 'Description',
          totalProducts: 'Total products',
          lastDay: 'Last day',
          lastSevenDays: 'Last 7 days',
          lastThirtyDays: 'Last 30 days',
        },
      },
    },
    product: {
      status: {
        ACTIVE: 'Active',
        OUT_OF_STOCK: 'Out of stock',
        URL_NOT_FOUND: 'Unavailable',
      },
    },
    attributeTypes: {
      BRAND: 'Brand',
      CATEGORY: 'Category',
      COLOR: 'Color',
      GENDER: 'Gender',
      IS_KIT_SAME_PRODUCT: 'Is kit same product',
      IS_KIT: 'Is kit',
      MAX_PET_WEIGHT: 'Max Pet Weight',
      MIN_PET_WEIGHT: 'Min Pet Weight',
      MODEL: 'Model',
      PACKAGE_TYPE: 'Package type',
      SELLER: 'Seller',
      SITE: 'Site',
      SIZE: 'Size',
      UNITS_PER_KIT: 'Units per kit',
      VOLUME: 'Volume',
    },
    attributes: {
      COLOR: {
        BLACK: 'Black',
        BLUE: 'Blue',
        BROWN: 'Brown',
        GRAY: 'Gray',
        GREEN: 'Green',
        INDIGO: 'Indigo',
        ORANGE: 'Orange',
        PINK: 'Pink',
        RED: 'Red',
        UNDEFINED: 'Undefined',
        VIOLET: 'Violet',
        WHITE: 'White',
        YELLOW: 'Yellow',
      },
      GENDER: {
        BOY: 'Boy',
        FEMALE: 'Female',
        GIRL: 'Girl',
        MALE: 'Male',
        UNDEFINED: 'Undefined',
        UNISEX: 'Unisex',
      },
      PACKAGE_TYPE: {
        REFILL: 'Refill',
        REGULAR: 'Regular',
        SAMPLE: 'Sample',
        SPECIAL_EDITION: 'Special Edition',
      },
      IS_KIT: {
        TRUE: 'Yes',
        FALSE: 'No',
      },
      IS_KIT_SAME_PRODUCT: {
        TRUE: 'Yes',
        FALSE: 'No',
      },
    },
  },
};
