import { resource as resourceAttributeTypeAPI } from 'api/mercadoradar/AttributeType';
import MercadoRadarAPIClient, {
  IListAPI,
  listEmptyResponse,
} from 'api/mercadoradar/MercadoRadarAPIClient';

const base_name = 'attribute';
const path = `/${base_name}/`;
const resource = `v2/${base_name}/`;

export declare interface IAttribute {
  id: number;
  value: string;
  // text: string; TODO
}

export declare interface IListAttribute extends IListAPI {
  results: IAttribute[];
}

export default class AttributeAPI extends MercadoRadarAPIClient {
  constructor() {
    super(resource);
  }

  async retrieve(id, params = null) {
    if (params) {
      if (!Object.hasOwnProperty.call(params, 'limit')) {
        params.limit = 50;
      }
    }
    params = this.formatParams(params);
    try {
      const response = await this.client.get(`${this.resource}${id}/`, {
        params,
      });
      return await response?.data;
    } catch {
      return listEmptyResponse;
    }
  }

  async coverage_by_brand() {
    const brand_id = 1;
    const response = await this.client.get(
      `v2/attribute-type/${brand_id}${path}coverage/`,
    );
    return await response.data;
  }
}
