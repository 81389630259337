import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  title: '',
  subTitle: [],
  loading: true,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderTitle(state, action) {
      state.title = action.payload;
    },

    setHeaderSubTitle(state, action) {
      state.subTitle = action.payload;
    },

    pushHeaderSubTitle(state, action) {
      state.subTitle.push(action.payload);
    },

    setHeaderLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  setHeaderTitle,
  setHeaderSubTitle,
  pushHeaderSubTitle,
  setHeaderLoading,
} = headerSlice.actions;
export default headerSlice.reducer;
