import { Select } from 'antd';
import { useAttributeTypeListQuery } from 'api/mercadoradar/MercadoRadarAPIClientV2';
import { Card } from 'components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupBy } from 'store/reportsSlice';

export default function MyComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const groupBy = useSelector((state) => state.reports.groupBy);
  const { data: options, isFetching } = useAttributeTypeListQuery();

  const handleChange = (value) => {
    dispatch(setGroupBy(value));
  };

  return (
    <Card
      className="d-flex flex-column justify-content-between"
      header={t('components.sideBar.groupBy')}
    >
      <Select
        disabled={isFetching}
        fieldNames={{ label: 'text', value: 'name' }}
        mode="multiple"
        options={options}
        placeholder="Adicionar agrupamento"
        showSearch={false}
        value={groupBy}
        onChange={handleChange}
      />
    </Card>
  );
}
