import { createColumnHelper } from '@tanstack/react-table';
import { Table } from 'components';
import { CustomColumnDef } from 'components/Table';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Attribute } from 'types/Attribute';
import { Site } from 'types/Site';

export default function CrawlerSearchesTable({ data, sorting, loading }) {
  type CoverageList = {
    site: Site;
    attribute: Attribute;
    description: string;
    url: string;
    total_products: number;
  };

  const { t } = useTranslation();

  const columnHelper = createColumnHelper<CoverageList>();
  const enableColumnResizing = true;
  const enableColumnSorting = true;

  const columns: CustomColumnDef<CoverageList, any>[] = [
    columnHelper.accessor('site', {
      header: t('screens.settings.coverageTable.site'),
      meta: {
        width: 0.25,
        cellClassName: 'd-flex align-items-start',
      },
      cell: (cell) => <>{cell.getValue()?.name}</>,
    }),
    columnHelper.accessor('attribute', {
      header: t('screens.settings.coverageTable.brand'),
      meta: {
        width: 0.25,
        cellClassName: 'd-flex align-items-start',
      },
      cell: (cell) => <>{cell.getValue()?.value}</>,
    }),
    columnHelper.accessor('description', {
      header: t('screens.settings.coverageTable.description'),
      meta: {
        width: 0.24,
        cellClassName: 'd-flex align-items-start',
      },
      cell: (cell) => (
        <>
          <a href={cell.row.original.url} rel="noreferrer" target="_blank">
            {cell.getValue()}
          </a>
        </>
      ),
    }),
    columnHelper.accessor('total_products', {
      header: t('screens.settings.coverageTable.totalProducts'),
      meta: {
        width: 0.24,
        headerClassName: 'd-flex align-items-center justify-content-center',
        cellClassName: 'd-flex align-items-center justify-content-center',
      },
      cell: (cell) => (
        <NumericFormat
          fixedDecimalScale
          decimalScale={0}
          decimalSeparator=","
          displayType="text"
          thousandSeparator="."
          value={cell.getValue()}
        />
      ),
    }),
  ];

  const tableProps = {
    columns,
    data,
    enableColumnResizing,
    enableColumnSorting,
    sorting,
  };

  return <Table {...tableProps} loading={loading} />;
}
